import React, { useState } from "react";
import { Button, Modal, Row, Col, FormControl } from "react-bootstrap";

export default function ({ onCreate }) {
  const emptyInfo = { name: "", minAmmount: 0, price: 0 };
  const [show, setShow] = useState(false);
  const [info, setInfo] = useState(emptyInfo);
  const resetState = () => {
    setShow(false);
    setInfo(emptyInfo);
  };
  const createPriceList = () => {
    if (info.name === "") {
      alert("Zadejte název dárku");
      return;
    }
    onCreate(info);
    resetState();
  };

  return (
    <>
      <Button variant="success" className="right" onClick={() => setShow(true)}>
        Přidat dárek
      </Button>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header className="bg-success text-white" closeButton>
          <Modal.Title>Přidat dárek</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} md={5}>
              Název
            </Col>
            <Col xs={12} md={7}>
              <FormControl
                type="text"
                placeholder="Název dárku"
                value={info.name}
                onChange={(e) => setInfo({ ...info, name: e.target.value })}
              ></FormControl>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={5}>
              Minimální odběr
            </Col>
            <Col xs={12} md={7}>
              <FormControl
                type="number"
                placeholder="Minimální odběr"
                value={info.minAmmount}
                onChange={(e) => setInfo({ ...info, minAmmount: parseInt(e.target.value) })}
              ></FormControl>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={5}>
              Minimální počet kusů
            </Col>
            <Col xs={12} md={7}>
              <FormControl
                type="number"
                placeholder="Minimální počet kusů"
                value={info.minNumberOfItems}
                onChange={(e) => setInfo({ ...info, minNumberOfItems: parseInt(e.target.value) })}
              ></FormControl>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={5}>
              Cena
            </Col>
            <Col xs={12} md={7}>
              <FormControl
                type="number"
                placeholder="Cena"
                value={info.price}
                onChange={(e) => setInfo({ ...info, price: parseInt(e.target.value) })}
              ></FormControl>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={resetState}>
            Zrušit
          </Button>
          <Button variant="success" onClick={createPriceList}>
            Vytvořit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
