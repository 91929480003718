import React, { useState } from "react";
import { Row, Col, FormControl, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";

export default function ({ discountCodes, setDiscountCodes }) {
  const [code, setCode] = useState("");

  const validateCode = () => {
    if (code === "" || discountCodes.filter((c) => c.Code === code).length > 0) {
      setCode("");
      return;
    }
    let formData = new FormData();
    formData.append("code", code);
    fetch(`/api/discountCodes/validate.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok)
          response.json().then((res) => {
            if (res === false) alert("Slevový kupón neexistuje nebo jeho platnost již vypršela");
            else {
              setDiscountCodes([...discountCodes, res]);
              setCode("");
            }
          });
      })
      .catch(console.log);
  };

  const removeCode = (code) => {
    setDiscountCodes(discountCodes.filter((c) => c.Code !== code));
  };

  return (
    <>
      <Row>
        <Col xs={12} md={8} lg={6}>
          <h3>Aplikovat slevový kód</h3>
          <Row>
            <Col xs={8}>
              <FormControl type="text" placeholder="Slevový kód" value={code} onChange={(e) => setCode(e.target.value)} />
            </Col>
            <Col xs={4}>
              <Button variant="primary" onClick={validateCode}>
                Ověřit a aplikovat
              </Button>
            </Col>
          </Row>
          {discountCodes.map((dc) => (
            <p key={dc.Code}>
              <FontAwesomeIcon onClick={() => removeCode(dc.Code)} icon={faMinus} style={{ cursor: "pointer", color: "red" }} />{" "}
              {dc.Code}: {dc.Description}
            </p>
          ))}
        </Col>
      </Row>
      <br />
    </>
  );
}
