import React, { useState } from "react";
import { OverlayTrigger, Tooltip, Modal, Row, Col, Button, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTimes } from "@fortawesome/free-solid-svg-icons";

export default function ({ onReject, email }) {
  const [show, setShow] = useState(false);
  const [reason, setReason] = useState("");
  const resetState = () => {
    setShow(false);
    setReason("");
  };

  const rejectUser = () => {
    if (reason.length === 0) {
      alert("Vyplňte důvod");
      return;
    }
    let formData = new FormData();
    formData.append("approve", false);
    formData.append("email", email);
    formData.append("reason", reason);
    fetch(`/api/users/approve.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          onReject();
          resetState();
        } else {
          alert("Při ukládání došlo k chybě");
          resetState();
        }
      })
      .catch(console.log);
  };

  return (
    <>
      <OverlayTrigger placement="bottom" overlay={<Tooltip>Zamítnout uživatele</Tooltip>}>
        <FontAwesomeIcon icon={faUserTimes} onClick={(e) => setShow(true)} className="text-danger pointer" />
      </OverlayTrigger>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header className="bg-danger text-white" closeButton>
          <Modal.Title>Zamítnout uživatele</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} md={2}>
              Důvod
            </Col>
            <Col xs={12} md={10}>
              <FormControl
                type="text"
                value={reason}
                onChange={(e) => {
                  setReason(e.target.value);
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={resetState}>
            Zrušit
          </Button>
          <Button variant="danger" onClick={rejectUser}>
            Zamítnout
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
