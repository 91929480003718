import React from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import config from "../Enviroment";
import {
  faAmbulance,
  faAddressCard,
  faCalendarAlt,
  faEnvelopeOpenText,
  faCog,
  faUserEdit,
  faSignOutAlt,
  faLayerGroup,
} from "@fortawesome/free-solid-svg-icons";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

function Logout(history, logout) {
  fetch("/api/users/logout.php", {
    method: "POST",
  }).then((response) => {
    logout();
    sessionStorage.clear();
    history.push("/");
  });
}

function Header({ loggedIn, userName, role, logout }) {
  let history = useHistory();
  return (
    <>
      <header>
        <h1>{config.header}</h1>
        <p className="left" dangerouslySetInnerHTML={{ __html: config.moto }}></p>
        <span className="right text-right" id="menuRightPart">
          {loggedIn ? (
            <>
              <span className="text-right" id="supportHeader">
                <p>
                  <b>Zákaznická linka:</b> <span dangerouslySetInnerHTML={{ __html: config.phone }}></span>
                  <br />
                  <b>Email:</b> {config.email}
                  <br />
                  {config.oppening}
                </p>
              </span>
              <span id="userInfoPart" className="text-right">
                <p>
                  {userName}
                  <OverlayTrigger placement="bottom" overlay={<Tooltip>Domů</Tooltip>}>
                    <Link to="/Home">
                      <FontAwesomeIcon icon={faAmbulance} />
                    </Link>
                  </OverlayTrigger>

                  {role === "Admin" ? (
                    <>
                      <OverlayTrigger placement="bottom" overlay={<Tooltip>Administrace uživatelů</Tooltip>}>
                        <Link to="/usersAdmin">
                          <FontAwesomeIcon icon={faAddressCard} />
                        </Link>
                      </OverlayTrigger>
                      <OverlayTrigger placement="bottom" overlay={<Tooltip>Administrace ceníků</Tooltip>}>
                        <Link to="/priceLists">
                          <FontAwesomeIcon icon={faLayerGroup} />
                        </Link>
                      </OverlayTrigger>
                      <OverlayTrigger placement="bottom" overlay={<Tooltip>Rozvoz - harmonogram</Tooltip>}>
                        <Link to="#">
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </Link>
                      </OverlayTrigger>

                      <OverlayTrigger placement="bottom" overlay={<Tooltip>Emailing</Tooltip>}>
                        <Link to="#">
                          <FontAwesomeIcon icon={faEnvelopeOpenText} />
                        </Link>
                      </OverlayTrigger>

                      <OverlayTrigger placement="bottom" overlay={<Tooltip>Administrace</Tooltip>}>
                        <Link to="/administration">
                          <FontAwesomeIcon icon={faCog} />
                        </Link>
                      </OverlayTrigger>
                    </>
                  ) : (
                    ""
                  )}
                  <OverlayTrigger placement="bottom" overlay={<Tooltip>Upravit profil</Tooltip>}>
                    <Link to="/EditProfile">
                      <FontAwesomeIcon icon={faUserEdit} />
                    </Link>
                  </OverlayTrigger>

                  <OverlayTrigger placement="bottom" overlay={<Tooltip>Odhlásit se</Tooltip>}>
                    <FontAwesomeIcon icon={faSignOutAlt} onClick={() => Logout(history, logout)} className="pointer" />
                  </OverlayTrigger>
                </p>
              </span>
            </>
          ) : (
            <span id="userInfoPart" className="text-right">
              <Link to="/Login">Přihlásit se</Link>
            </span>
          )}
        </span>
      </header>
      <div id="headerFixer"></div>
    </>
  );
}

export default Header;
