import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Modal, FormControl } from "react-bootstrap";
import PriceTableCreate from "./CretePriceTableModal";
import PriceTableEditor from "./TableEditor";
import GiftAdder from "./NewGift";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faEdit } from "@fortawesome/free-solid-svg-icons";
import SettingsEditor from "./SettingsEditor";
import { Prompt } from "react-router-dom";

const emptyPriceList = {
  name: "",
  id: -1,
  tables: [],
  gifts: [],
  giftGroups: [],
  settings: {
    validFrom: "",
    minAmmount: 0,
    minAmmountPersonal: 0,
    add: null,
    showCalendar: true,
    showMoreGoods: true,
    RefundList: false,
    DeliveryDays: {
      Mo: true,
      Tu: true,
      We: true,
      Th: true,
      Fr: true,
    },
    PreparedDeliveryDays: {
      Mo: false,
      Tu: false,
      We: false,
      Th: false,
      Fr: false,
    },
  },
};

function createNewEmptyTable(name, format, tableIndex) {
  return {
    data: [],
    name,
    format,
    settings: {
      hide: false,
      add: null,
      minAmmount: 0,
      order: tableIndex,
      countTowardsGifts: true,
    },
  };
}

export default function PriceLists({ match }) {
  const [priceList, setPriceListCallBack] = useState(emptyPriceList);
  const [images, setImages] = useState([]);
  const [changes, setChanges] = useState(false);
  const [isBasedOn, setIsBasedOn] = useState(false);
  const [basedOnName, setBasedOnName] = useState("");

  const setPriceList = (data) => {
    setChanges(true);
    setPriceListCallBack(data);
    setIsBasedOn(data.basedOn && data.basedOn !== "-1");
  };

  useEffect(() => {
    fetch(`/api/priceLists/get.php?id=${match.params.id}`)
      .then((res) => res.json())
      .then((data) => {
        setPriceListCallBack(data);
        setIsBasedOn(data.basedOn && data.basedOn !== "-1");
      })
      .catch(console.log);
    fetch("/api/administration/getImages.php")
      .then((res) => res.json())
      .then((data) => setImages(data))
      .catch(console.log);
  }, [match.params.id]);

  useEffect(() => {
    if (priceList.basedOn && priceList.basedOn !== "-1") {
      fetch(`/api/priceLists/get.php?id=${priceList.basedOn}`)
        .then((res) => res.json())
        .then((data) => {
          setBasedOnName(data.name);
        })
        .catch(console.log);
    }
  }, [priceList]);

  const createPriceTable = (name, format) => {
    setPriceList({ ...priceList, tables: [...priceList.tables, createNewEmptyTable(name, format, priceList.tables.length)] });
  };

  const savePriceList = (priceList) => {
    let formData = new FormData();
    formData.append("priceList", JSON.stringify(priceList));
    formData.append("id", priceList.id);
    fetch(`/api/priceLists/update.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          setChanges(false);
          alert("Změny byly uloženy");
        } else {
          alert("Při ukládání došlo k chybě");
        }
      })
      .catch(console.log);
  };

  return (
    <>
      <Prompt when={changes} message="Jste si jistí, že chcete odejít? Všechny neuložené změny budou smazány." />
      <Row className="justify-content-md-center">
        <Col xs={12} lg={10}>
          <Card>
            <Card.Header>Tabulky ceníku</Card.Header>
            <Card.Body>
              {priceList.tables.sort((a, b) => a.order - b.order).map((t, i) => (
                <PriceTableEditor
                  key={i}
                  onChange={(newTable) => {
                    let newTables = [...priceList.tables];
                    newTables[i] = newTable;
                    setPriceList({ ...priceList, tables: newTables });
                  }}
                  onDelete={() => {
                    let newTables = [...priceList.tables];
                    newTables.splice(i, 1);
                    setPriceList({ ...priceList, tables: newTables });
                  }}
                  onSwapTables={(i, j) => {
                    let newTables = priceList.tables.map(table => {
                      if (table.order === i) { table.order = j; }
                      else if (table.order === j) { table.order = i; }
                      return table;
                    });
                    setPriceList({ ...priceList, tables: newTables });
                  }}
                  numberOfTables={priceList.tables.length}
                  table={t}
                  images={images}
                  isBasedOn={isBasedOn}
                />
              ))}
            </Card.Body>
          </Card>
          <PriceTableCreate onCreate={createPriceTable} />
        </Col>
      </Row>
      <br />
      <br />
      <Row className="justify-content-md-center">
        <Col xs={12} md={6} lg={5}>
          <SettingsEditor
            settings={priceList.settings}
            name={priceList.name}
            onNameChange={(newName) => setPriceList({ ...priceList, name: newName })}
            onSettingsChange={(prop, newVal) => {
              let newSett = { ...priceList.settings };
              newSett[prop] = newVal;
              setPriceList({ ...priceList, settings: newSett });
            }}
            images={images}
            basedOnName={basedOnName}
            giftGroups={priceList.giftGroups}
          />
        </Col>

        {!isBasedOn && (<Col xs={12} md={6} lg={5}>
          <Card>
            <Card.Header>Dárky</Card.Header>
            <Card.Body>
              {priceList.gifts.map((g, i) => (
                <Row key={i}>
                  <Col xs={12}>
                    <FontAwesomeIcon
                      icon={faMinus}
                      className="text-danger pointer"
                      onClick={() => {
                        let newGifts = [...priceList.gifts];
                        newGifts.splice(i, 1);
                        setPriceList({ ...priceList, gifts: newGifts/*, giftGroups: priceList.giftGroups.map(giftGroup => giftGroup.filter(giftName => giftName === g.name)) */});
                      }}
                    />{" "}
                    {g.name}: od {g.minAmmount} Kč (cena: {g.price} Kč)
                  </Col>
                </Row>
              ))}
            </Card.Body>
            <GiftAdder
              onCreate={(info) =>
                setPriceList({ ...priceList, gifts: [...priceList.gifts, info].sort((a, b) => a.minAmmount - b.minAmmount) })
              }
            />
          </Card>

            <GiftGroupsPanel
              gifts={priceList.gifts}
              giftGroups={priceList.giftGroups}
              setGiftGroups={(giftGroups) => setPriceList({...priceList, giftGroups })}
              />
        </Col>)}

      </Row>
      <Button
        variant="primary"
        className="right"
        style={{ position: "sticky", bottom: "32px", right: "32px" }}
        onClick={() => savePriceList(priceList)}
      >
        Uložit změny
      </Button>
    </>
  );
}

function GiftGroupsPanel({ gifts, giftGroups, setGiftGroups }) {
  const emptyGiftGroup = () => {
    return {id: giftGroups.length + 1, name: "", gifts: []};
  };

  const [giftGroupInPopUp, setGiftGroupInPopUp] = useState(emptyGiftGroup());
  const [isNewGiftGroupInPopUp, setIsNewGiftGroupInPopUp] = useState(true);
  const [showGiftGroupsPopUp, setShowGiftGroupsPopUp] = useState(false);

  const createGiftGroup = () => {
    setGiftGroupInPopUp(emptyGiftGroup());
    setIsNewGiftGroupInPopUp(true);
    setShowGiftGroupsPopUp(true);
  };

  const editGiftGroup = (giftGroup) => {
    setGiftGroupInPopUp(giftGroup);
    setIsNewGiftGroupInPopUp(false);
    setShowGiftGroupsPopUp(true);
  };

  const submitGiftGroupFromPopUp = (giftGroup) => {
    setGiftGroups([...giftGroups.filter(x => x.id !== giftGroup.id), giftGroup]);
  };

  const removeGiftGroup = (giftGroup) => {
    setGiftGroups(giftGroups.filter(x => x.id !== giftGroup.id));
  };

  return (
    <Card>
      <Card.Header>Skupiny dárků</Card.Header>
      <Card.Body>

      {giftGroups.map((giftGroup, giftGroupIndex) =>
          (
            <Row>
              <Col xs="12">
                <FontAwesomeIcon
                  icon={faEdit}
                  className="text-primary pointer"
                  onClick={() => editGiftGroup(giftGroup)}
                />

                <FontAwesomeIcon
                  icon={faMinus}
                  className="text-danger pointer"
                  style={{ marginLeft: "4px" }}
                  onClick={() => removeGiftGroup(giftGroup)}
                  />

              <span style={{ marginLeft: "8px" }}>{giftGroup.name}</span>
              </Col>
            </Row>
          )
          )
        }
      </Card.Body>

      <Button variant="success" onClick={createGiftGroup}>Přidat skupinu dárků</Button>
      <GiftGroupsPopUp
        gifts={gifts}
        show={showGiftGroupsPopUp}
        setShow={setShowGiftGroupsPopUp}
        giftGroup={giftGroupInPopUp}
        isNew={isNewGiftGroupInPopUp}
        submitGiftGroup={submitGiftGroupFromPopUp}
      />


    </Card>
  );
}

function GiftGroupsPopUp({ show, setShow, gifts, giftGroup, submitGiftGroup, isNew }) {
  const [myGiftGroup, setMyGiftGroup] = useState({...giftGroup});

  useEffect(() => {
    if (show) {
      setMyGiftGroup(giftGroup);
    }
  }, [show, giftGroup]);

  return (
    <Modal show={show} onHide={() => setShow(false)} dialogClassName="wide-modal">
      <Modal.Header className="bg-success text-white" closeButton>
        <Modal.Title>{isNew ? "Nová skupina dárků" : "Upravit skupinu dárků"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <h5 className="mb-3">Název</h5>
          <Row>
            <Col xs={12} md={12}>
              <FormControl
                type="text"
                placeholder="Zadejte název skupiny"
                value={myGiftGroup.name}
                onChange={(e) => setMyGiftGroup({ ...myGiftGroup, name: e.target.value })}
              ></FormControl>
            </Col>
          </Row>
        <h5 className="mt-3 mb-3">Obsažené dárky</h5>
        {gifts.map((gift) => (
          <Row>
            <Col>
              <input
                type="checkbox"
                checked={myGiftGroup.gifts.includes(gift.name)}
                onChange={() => {
                  setMyGiftGroup({...myGiftGroup,
                    gifts: myGiftGroup.gifts.includes(gift.name)
                    ? myGiftGroup.gifts.filter(x => x !== gift.name)
                    : [...myGiftGroup.gifts, gift.name]})
                }}
                />
              <label style={{ marginLeft: "8px" }}>{gift.name}: od {gift.minAmmount} Kč (cena: {gift.price} Kč)</label>
            </Col>
          </Row>
        ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => { submitGiftGroup(myGiftGroup); setShow(false); }}>
            Uložit
          </Button>
        </Modal.Footer>
    </Modal>
  );
}
