import React from "react";
import { Route, Link } from "react-router-dom";
import { Button } from "react-bootstrap";

function isAuthorized(loggedIn, userInfo, requiredRoles) {
  if (!loggedIn) return false;
  if (requiredRoles === undefined || requiredRoles.length === 0) return true;
  return requiredRoles.includes(userInfo.role);
}

function AuthorizedRoute(props) {
  return isAuthorized(props.loggedIn, props.userInfo, props.roles) ? (
    <Route {...props} />
  ) : (
    <>
      Pro zobrazení této stránky se musíte nejprve přihlásit nebo nemáte dostatečná oprávnění.
      <br />
      <Link to="/">
        <Button>Přihlásit se</Button>
      </Link>
    </>
  );
}

export default AuthorizedRoute;
