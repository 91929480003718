import React, { useState } from "react";
import { OverlayTrigger, Tooltip, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

export default function ({ onDelete, id }) {
  const [show, setShow] = useState(false);
  const resetState = () => {
    setShow(false);
  };

  const deletePriceList = () => {
    let formData = new FormData();
    formData.append("id", id);
    fetch(`/api/priceLists/delete.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          onDelete();
          resetState();
        } else {
          console.log(response.status, response.statusText);
        }
      })
      .catch(console.log);
  };

  return (
    <>
      <OverlayTrigger placement="bottom" overlay={<Tooltip>Smazat ceník</Tooltip>}>
        <FontAwesomeIcon icon={faTrash} className="text-danger pointer" onClick={(e) => setShow(true)} />
      </OverlayTrigger>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header className="bg-danger text-white" closeButton>
          <Modal.Title>Smazat ceník</Modal.Title>
        </Modal.Header>
        <Modal.Body>Opravdu chcete smazat tento ceník?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={resetState}>
            Zrušit
          </Button>
          <Button variant="danger" onClick={deletePriceList}>
            Smazat
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
