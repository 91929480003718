import React, { useState, useEffect } from "react";
import { urlFormatParams, separateThousands } from "../../common";
import PriceListInfo from "./PriceListInfo";
import PriceTable, { calcTableStats } from "./PriceTable";
import GiftsContainer from "./Gifts";
import StorageKeys from "../../localStorageKeys";
import { Col, Row, FormLabel as Label, Form, Button, Alert } from "react-bootstrap";
import AddView from "../../Components/AddView";
import config from "../../Enviroment";
import DiscountCodesContainer from "./DiscountCodesContainer";

const emptyPriceList = {
  name: "",
  id: undefined,
  tables: [],
  gifts: [],
  settings: {
    validFrom: "",
    minAmmount: 0,
    minAmmountPersonal: 0,
    add: null,
    showCalendar: true,
    showMoreGoods: true,
    RefundList: false,
    DeliveryDays: {
      Mo: true,
      Tu: true,
      We: true,
      Th: true,
      Fr: true,
    },
    PreparedDeliveryDays: "",
  },
};

function roundTo2Decimals(value) {
  return value.toFixed(2);
}

export function calculateTotalPrice(tables, ammounts, discountCodes) {
  let price = 0;
  let ammount = 0;
  let canBeOrdered = true;
  let giftAmount = 0;
  let giftPrice = 0;
  tables.forEach((t) => {
    const { totalAmmount, totalPrice, isAmmountOk } = calcTableStats(t, ammounts[t.id], discountCodes);
    price += totalPrice;
    ammount += totalAmmount;
    if (t.settings.countTowardsGifts) {
      giftAmount += totalAmmount;
      giftPrice += totalPrice;
    }
    canBeOrdered = canBeOrdered && isAmmountOk;
  });
  return [price, ammount, canBeOrdered, giftPrice, giftAmount];
}

export default function ({ admin, history }) {
  const [state, setState] = useState({ priceList: emptyPriceList, ammounts: {}, selectedGiftId: -2, discountCodes: [], basedOnPriceList: null });
  const [allPriceLists, setAllPriceLists] = useState([]);
  const { priceList, ammounts, selectedGiftId: selectedGiftIdState, discountCodes, basedOnPriceList } = state;

  const changeAmmount = (tableId, productId, newVal) => {
    let newAmm = { ...ammounts };
    newAmm[tableId] = { ...ammounts[tableId] };
    newAmm[tableId][productId] = newVal;
    setState({ ...state, ammounts: newAmm });
  };

  const selectGiftId = (id) => {
    if (selectedGiftId === id) {
      setState({ ...state, selectedGiftId: -1 });
    } else {
      setState({ ...state, selectedGiftId: id });
    }
  };

  const loadPriceList = (email, priceList) => {

    let data = {};
    if (email !== undefined) data.email = email;
    if (priceList !== undefined) data.id = priceList;
    fetch("/api/priceLists/get.php" + urlFormatParams(data))
      .then((res) => res.json())
      .then((list) => {
        let newAmmounts = {};
        let tId = 0;
        list.tables.forEach((t) => {
          t.id = tId++;
          newAmmounts[t.id] = {};
          let dId = 0;
          t.data.forEach((d) => {
            d.id = dId++;
            newAmmounts[t.id][d.id] = 0;
          });
        });
        if (list.basedOn === "" || list.basedOn === null || list.basedOn === undefined || parseInt(list.basedOn) === -1) {
          setState({
            priceList: list,
            ammounts: newAmmounts,
            selectedGiftId: -2,
            discountCodes: [],
          });
        } else {
          let data = {};
          data.id = list.basedOn;
          fetch("/api/priceLists/get.php" + urlFormatParams(data))
          .then(res => res.json())
          .then((basedOnList) => {
            let tId = 0;
            basedOnList.tables.forEach((t) => {
              t.id = tId++;
              let dId = 0;
              t.data.forEach((d) => {
                d.id = dId++;
              });
            });
            setState({
              priceList: list,
              ammounts: newAmmounts,
              selectedGiftId: -2,
              discountCodes: [],
              basedOnPriceList: basedOnList
            });
          })
          .catch(console.log);
        }
      })
      .catch(console.log);
  };

  useEffect(() => {
    const cachedState = JSON.parse(sessionStorage.getItem(StorageKeys.OrderStateKey));
    const cachedPriceLists = JSON.parse(sessionStorage.getItem(StorageKeys.PriceListsKey));
    if (admin) {
      if (cachedPriceLists === null) {
        fetch("/api/priceLists/getAll.php")
          .then((res) => res.json())
          .then((data) => {
            sessionStorage.setItem(StorageKeys.PriceListsKey, JSON.stringify(data));
            setAllPriceLists(data);
          })
          .catch(console.log);
      } else {
        setAllPriceLists(cachedPriceLists);
      }
    }
    if (cachedState !== null) {
      setState(cachedState);
    } else if (!admin) {
      loadPriceList();
    }
  }, [admin]);

  let [price, ammount, canBeOrdered, giftPrice, giftAmount] = calculateTotalPrice(priceList.tables, ammounts, discountCodes);

  let email;
  let priceListId;
  const getBestGiftId = () => {
    return state.priceList.gifts.filter((g) => g.minAmmount <= giftPrice && g.minNumberOfItems <= giftAmount).length - 1;
  };

  const getActiveGifts = (priceList) => {
    if (!priceList.settings.giftGroup || priceList.settings.giftGroup === -1) {
      return priceList.gifts;
    }
    const selectedGiftGroup = priceList.giftGroups.filter(giftGroup => parseInt(giftGroup.id) === parseInt(priceList.settings.giftGroup))[0];
    if(selectedGiftGroup === undefined)
      return []
    const activeGifts = priceList.gifts.filter(gift => selectedGiftGroup.gifts.includes(gift.name));
    return activeGifts;
  };

  const lollypopsSpecialCase = (tables, amounts) => {
    let lollypopAmount = 0;
    let totalAmount = 0;
    for (const table of tables) {
      for (const item of table["data"]) {
        if (amounts[table.id][item.id] > 0) {
          if (item.name.match(/(^| )lízátk[oa]($| )/)) {
            lollypopAmount += amounts[table.id][item.id];
          }
          totalAmount += amounts[table.id][item.id];
        }
      }
    }
    return lollypopAmount >= 1 && totalAmount > 1;
  };


  const selectedGiftId = selectedGiftIdState === -2 ? getBestGiftId() : selectedGiftIdState;

  return (
    <>
      {admin ? (
        <>
          <Row>
            <Col xs={12} md={6}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  e.target.reset();
                  if (priceListId === -1 || priceListId === undefined) return;
                  loadPriceList(undefined, priceListId);
                }}
              >
                <Row>
                  <Col xs={12} md={3}>
                    <Label>Vybrat ceník</Label>
                  </Col>
                  <Col xs={10} md={7}>
                    <Form.Control as="select" onChange={(e) => (priceListId = e.target.value)}>
                      <option value={-1}></option>
                      {allPriceLists.map((p) => (
                        <option key={p.id} value={parseInt(p.id)}>
                          {p.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                  <Col xs={2} md={2}>
                    <Button type="submit">Ok</Button>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col xs={12} md={6}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  e.target.reset();
                  loadPriceList(email);
                }}
              >
                <Row>
                  <Col xs={12} md={3}>
                    <Label>Vyhledat ceník</Label>
                  </Col>
                  <Col xs={10} md={7}>
                    <Form.Control
                      placeholder="email"
                      defaultValue={email}
                      onChange={(e) => (email = e.target.value)}
                      required
                    />
                  </Col>
                  <Col xs={2} md={2}>
                    <Button type="submit">Ok</Button>
                  </Col>{" "}
                </Row>
              </Form>
            </Col>
          </Row>
          <br />
          <hr />
        </>
      ) : (
        ""
      )}
      {!priceList.id && !admin && (
        <Alert variant="danger" className="text-center" style={{ width: "80%", margin: "8px 10%" }}>
          {config.noPriceListText}
        </Alert>
      )}
      <PriceListInfo priceList={priceList} />
      {priceList.settings.add && <AddView src={`/img/${priceList.settings.add}`} addLink={priceList.settings.addLink} />}
      {priceList.tables.sort((a, b) => a.order - b.order).map((table) => (
        <PriceTable
          key={table.id}
          table={table}
          discountCodes={discountCodes}
          ammounts={ammounts[table.id]}
          inputClickHandler={changeAmmount}
          basedOnPriceList={basedOnPriceList}
        />
      ))}

      {priceList.id && priceList.settings.useDiscountCode && (
        <DiscountCodesContainer
          discountCodes={discountCodes}
          setDiscountCodes={(codes) => setState({ ...state, discountCodes: codes })}
        />
      )}

      <GiftsContainer gifts={getActiveGifts(priceList)} totalPrice={giftPrice} totalNumberOfItems={giftAmount} selectedGift={selectedGiftId} selectGift={selectGiftId} />
      {priceList.id && (
        <div className="right text-right" style={{ width: "100%" }}>
          <h3>Celková cena objednávky: {separateThousands(roundTo2Decimals(selectedGiftId === -1 ? price : price + priceList.gifts[selectedGiftId].price))} Kč</h3>
          <h5>Celkový odběr: {ammount} ks</h5>
          <h5>Celková započitatelná cena objednávky (dárky): {separateThousands(roundTo2Decimals(giftPrice))} Kč</h5>
          <h5>Celkový započitatelný odběr (dárky): {giftAmount} ks</h5>
          {(price < priceList.settings.minAmmount || ammount < (priceList.settings.minNumberOfItems ?? 0)) && !lollypopsSpecialCase(priceList.tables, ammounts) ? (
            <Alert variant="warning" className="text-center" style={{ width: "80%", margin: "8px 10%" }}>
              Vaše objednávka je nižší, než je minimální výše objednávky. Doplňte, prosím, ještě Vaši objednávku. Děkujeme.
            </Alert>
          ) : (
            ""
          )}
          <Button
            variant={lollypopsSpecialCase(priceList.tables, ammounts) || (canBeOrdered && price >= priceList.settings.minAmmount && ammount >= (priceList.settings.minNumberOfItems ?? 0)) ? `success` : "danger"}
            onClick={() => {
              if (!lollypopsSpecialCase(priceList.tables, ammounts) &&
                (!canBeOrdered || price < priceList.settings.minAmmount || ammount < (priceList.settings.minNumberOfItems ?? 0)) ) {
                alert("Vaše objednávka je nižší, než je minimální výše objednávky. Doplňte, prosím, ještě Vaši objednávku. Děkujeme.");
              } else {
                let strValue = { ...state, selectedGiftId };
                sessionStorage.setItem(StorageKeys.OrderStateKey, JSON.stringify(strValue));
                history.push("/OrderRecapitulation");
              }
            }}
          >
            Rekapitulace objednávky
          </Button>
        </div>
      )}
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
}
