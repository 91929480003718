import React, { useState } from "react";
import { Button, Modal, Row, Col, FormControl } from "react-bootstrap";
import dateformat from "dateformat";

export default function ({ onCreate }) {
  const emptyState = {
    code: "",
    desc: "",
    sukl: "",
    minAmmount: 0,
    discount: 0,
    validFrom: dateformat(new Date(), "yyyy-mm-dd"),
    validTo: dateformat(new Date(), "yyyy-mm-dd"),
  };

  const [show, setShow] = useState(false);
  const [formState, setFormState] = useState(emptyState);

  const resetState = () => {
    setShow(false);
    setFormState(emptyState);
  };
  const createPriceList = () => {
    if (formState.code === "") {
      alert("Zadejte slevový kód");
      return;
    }
    let formData = new FormData();
    formData.append("code", formState.code);
    formData.append("discount", formState.discount);
    formData.append("validFrom", formState.validFrom);
    formData.append("validTo", formState.validTo);
    formData.append("sukl", formState.sukl);
    formData.append("description", formState.desc);
    formData.append("minAmmount", formState.minAmmount);
    fetch(`/api/discountCodes/create.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          onCreate(formState);
          resetState();
        }
      })
      .catch(console.log);
  };

  console.log(formState.validFrom.toString("yyyy-MM-dd"));

  return (
    <>
      <Button variant="success" className="right" onClick={() => setShow(true)}>
        Přidat slevový kód
      </Button>

      <Modal size={"lg"} show={show} onHide={() => setShow(false)}>
        <Modal.Header className="bg-success text-white" closeButton>
          <Modal.Title>Přidat slevový kód</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} md={2}>
              Kód
            </Col>
            <Col xs={12} md={10}>
              <FormControl
                type="text"
                placeholder="Slevový kód"
                value={formState.code}
                onChange={(e) => setFormState({ ...formState, code: e.target.value })}
              ></FormControl>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={2}>
              Popisek
            </Col>
            <Col xs={12} md={10}>
              <FormControl
                type="text"
                placeholder="Popisek"
                value={formState.desc}
                onChange={(e) => setFormState({ ...formState, desc: e.target.value })}
              ></FormControl>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={2}>
              Súkl
            </Col>
            <Col xs={12} md={10}>
              <FormControl
                type="text"
                placeholder="Súkl produktu, pro který se kupón aplikuje (nechte prázdné, pokud se vztahuje na celý ceník)"
                value={formState.sukl}
                onChange={(e) => setFormState({ ...formState, sukl: e.target.value })}
              ></FormControl>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={2}>
              Minimální odběr
            </Col>
            <Col xs={12} md={10}>
              <FormControl
                type="number"
                placeholder="Minimální odběr produktu pro aplikaci kódu"
                value={formState.minAmmount}
                onChange={(e) => setFormState({ ...formState, minAmmount: parseInt(e.target.value) })}
              ></FormControl>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={2}>
              Sleva
            </Col>
            <Col xs={12} md={10}>
              <FormControl
                type="number"
                placeholder="Slava po aplikaci kódu"
                value={formState.discount}
                onChange={(e) => setFormState({ ...formState, discount: parseInt(e.target.value) })}
              ></FormControl>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={2}>
              Platnost od
            </Col>
            <Col xs={12} md={10}>
              <FormControl
                type="date"
                value={formState.validFrom.toString("yyyy-MM-dd")}
                onChange={(e) => setFormState({ ...formState, validFrom: e.target.value })}
              ></FormControl>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={2}>
              Platnost do
            </Col>
            <Col xs={12} md={10}>
              <FormControl
                type="date"
                value={formState.validTo}
                onChange={(e) => setFormState({ ...formState, validTo: e.target.value })}
              ></FormControl>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={resetState}>
            Zrušit
          </Button>
          <Button variant="success" onClick={createPriceList}>
            Vytvořit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
