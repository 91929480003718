import React, { useState, useEffect } from "react";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import { Switch, Route, Redirect, HashRouter } from "react-router-dom";
import AuthorizedRoute from "./AuthorizedRoute";
import Administration from "./Sites/Administration/Administration";
import EditProfile from "./Sites/EditProfile/EditProfile";
import Login from "./Sites/Login/Login";
import Order from "./Sites/Order/Order";
import OrderRecapitulation from "./Sites/OrderRecapitulation/OrderRecapitulation";
import "bootstrap/dist/css/bootstrap.min.css";
import PriceLists from "./Sites/PriceLists/PriceLists";
import EditPriceList from "./Sites/EditPriceList/EditPriceList";
import UsersAdministration from "./Sites/UsersAdministration/UsersAdministration";
import Register from "./Sites/Register/Register";
import RenewPwd from "./Sites/RenewPwd/RenewPwd";

function App() {
  const [isLogged, setIsLogged] = useState(false);
  const [loggedInfo, setLoggedInfo] = useState({
    name: "",
    email: "",
    role: "",
  });
  const checkLogIn = () => {
    fetch("/api/users/get.php?stampOnly=true")
      .then((res) => res.json())
      .then((data) => {
        if (data.logged) {
          setLoggedInfo({
            email: data.email,
            name: data.name,
            role: data.role,
          });
        } else {
          sessionStorage.clear();
        }
        setIsLogged(data.logged);
      })
      .catch(console.log);
  };
  const logout = () => {
    setIsLogged(false);
    setLoggedInfo({ name: "", email: "", role: "" });
  };
  useEffect(checkLogIn, []);
  return (
    <HashRouter>
      <Header loggedIn={isLogged} userName={loggedInfo.name} role={loggedInfo.role} logout={logout} />
      <article>
        <Switch>
          <AuthorizedRoute
            loggedIn={isLogged}
            userInfo={loggedInfo}
            exact
            path="/Home"
            render={(props) => <Order admin={loggedInfo.role === "Admin"} {...props} />}
          />
          <AuthorizedRoute loggedIn={isLogged} userInfo={loggedInfo} exact path="/Administration" component={Administration} />
          <AuthorizedRoute loggedIn={isLogged} userInfo={loggedInfo} exact path="/EditProfile" component={EditProfile} />
          <AuthorizedRoute
            loggedIn={isLogged}
            userInfo={loggedInfo}
            exact
            path="/OrderRecapitulation"
            component={OrderRecapitulation}
          />
          <AuthorizedRoute
            loggedIn={isLogged}
            userInfo={loggedInfo}
            exact
            path="/EditPriceList/:id"
            component={EditPriceList}
          />
          <AuthorizedRoute loggedIn={isLogged} userInfo={loggedInfo} exact path="/priceLists" component={PriceLists} />
          <AuthorizedRoute loggedIn={isLogged} userInfo={loggedInfo} exact path="/usersAdmin" component={UsersAdministration} />
          <Route exact path="/Register" render={(props) => <Register checkLogin={checkLogIn} {...props} />} />
          <Route exact path="/renewPwd/:securityToken" component={RenewPwd} />
          <Route exact path="/" render={(props) => <Login checkLogin={checkLogIn} {...props} />} />
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </article>
      <Footer loggedIn={isLogged} userName={loggedInfo.name} />
    </HashRouter>
  );
}

export default App;
