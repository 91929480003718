import React, { useState } from "react";
import { OverlayTrigger, Tooltip, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserSlash } from "@fortawesome/free-solid-svg-icons";

export default function ({ onDelete, email }) {
  const [show, setShow] = useState(false);
  const resetState = () => {
    setShow(false);
  };

  const deleteUser = (i) => {
    let formData = new FormData();
    formData.append("email", email);
    fetch(`/api/users/delete.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          onDelete();
          resetState();
        } else {
          alert("Při ukládání došlo k chybě");
        }
      })
      .catch(console.log);
  };

  return (
    <>
      <OverlayTrigger placement="bottom" overlay={<Tooltip>Smazat uživatele</Tooltip>}>
        <FontAwesomeIcon icon={faUserSlash} className="text-danger pointer" onClick={(e) => setShow(true)} />
      </OverlayTrigger>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header className="bg-danger text-white" closeButton>
          <Modal.Title>Smazat uživatele</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Opravdu chcete smazat tohoto uživatele?
          <br />
          email: {email}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={resetState}>
            Zrušit
          </Button>
          <Button variant="danger" onClick={deleteUser}>
            Smazat
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
