import React, { useState, useEffect } from "react";
import { Card, Table, Badge, Row, Col, OverlayTrigger, Tooltip, Pagination } from "react-bootstrap";
import { dayToNum, numToDayCz } from "../../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCheck, faInfo } from "@fortawesome/free-solid-svg-icons";
import PriceListSelector from "./SelectPriceList";
import UserDeletor from "./DeleteUser";
import UserRejector from "./RejectUser";
import config from "../../Enviroment";

function ChildRow({ usr }) {
  return (
    <tr>
      <td colSpan={10}>
        <Row>
          <Col xs={4}>
            <h5>Fakturační adresa</h5>
            <Row>
              <Col xs={3}>
                <b>Firma:</b>
              </Col>
              <Col xs={9}>{usr.CompanyName}</Col>
            </Row>
            <Row>
              <Col xs={3}>
                <b>Ulice:</b>
              </Col>
              <Col xs={9}>{usr.Street}</Col>
            </Row>
            <Row>
              <Col xs={3}>
                <b>ČP:</b>
              </Col>
              <Col xs={9}>{usr.HouseNum}</Col>
            </Row>
            <Row>
              <Col xs={3}>
                <b>PSČ:</b>
              </Col>
              <Col xs={9}>{usr.ZIP}</Col>
            </Row>
            <Row>
              <Col xs={3}>
                <b>Město:</b>
              </Col>
              <Col xs={9}>{usr.City}</Col>
            </Row>
          </Col>
          <Col xs={4}>
            <h5>Dodací adresa</h5>
            <Row>
              <Col xs={3}>
                <b>Firma:</b>
              </Col>
              <Col xs={9}>{usr.DelCompanyName}</Col>
            </Row>
            <Row>
              <Col xs={3}>
                <b>Ulice:</b>
              </Col>
              <Col xs={9}>{usr.DelStreet}</Col>
            </Row>
            <Row>
              <Col xs={3}>
                <b>ČP:</b>
              </Col>
              <Col xs={9}>{usr.DelHouseNum}</Col>
            </Row>
            <Row>
              <Col xs={3}>
                <b>PSČ:</b>
              </Col>
              <Col xs={9}>{usr.DelZIP}</Col>
            </Row>
            <Row>
              <Col xs={3}>
                <b>Město:</b>
              </Col>
              <Col xs={9}>{usr.DelCity}</Col>
            </Row>
          </Col>
          <Col xs={4}>
            <h5>Ordinační hodiny</h5>
            {Object.keys(usr.OppeningHours).map((day) => (
              <Row key={day}>
                <Col xs={3}>
                  <b>{numToDayCz(dayToNum(day))}:</b>
                </Col>
                <Col xs={9}>
                  {usr.OppeningHours[day].TimeFrom} -&gt; {usr.OppeningHours[day].TimeTo}
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
      </td>
    </tr>
  );
}

export default function () {
  const [users, setUsers] = useState([]);
  const [showDetails, setShowDetails] = useState([]);
  const [priceLists, setPriceLists] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(0);
  const rowsPerPage = 20;

  const loadPriceLists = () => {
    fetch("/api/priceLists/getAll.php")
      .then((res) => res.json())
      .then((data) => setPriceLists(data))
      .catch(console.log);
  };

  const approveUser = (i) => {
    let formData = new FormData();
    formData.append("approve", true);
    formData.append("email", users[i].Email);
    fetch(`/api/users/approve.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          let newUsers = [...users];
          newUsers[i] = { ...users[i], Status: "Approved" };
          setUsers(newUsers);
        } else {
          alert("Při ukládání došlo k chybě");
        }
      })
      .catch(console.log);
  };

  useEffect(() => {
    fetch("/api/users/getAll.php")
      .then((res) => res.json())
      .then((data) => setUsers(data))
      .catch(console.log);
  }, []);
  return (
    <>
      <Card>
        <Card.Header>Uživatelé</Card.Header>
        <Card.Body>
          <Table variant="hover">
            <thead>
              <tr>
                <th>Jméno</th>
                <th>Email</th>
                <th>Telefon</th>
                <th>IČO</th>
                <th>DIČ</th>
                <th>DPH</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {users.map((usr, index) => {
                return (
                  index >= currentPage * rowsPerPage &&
                  index < (currentPage + 1) * rowsPerPage && (
                    <React.Fragment key={usr.Email}>
                      <tr
                        className="pointer"
                        onClick={(e) => {
                          if (showDetails.includes(usr.Email)) {
                            setShowDetails(showDetails.filter((d) => d !== usr.Email));
                          } else {
                            setShowDetails([...showDetails, usr.Email]);
                          }
                        }}
                      >
                        <td>
                          <FontAwesomeIcon icon={faInfo} className="text-info" /> {usr.Name}
                        </td>
                        <td>{usr.Email}</td>
                        <td>{usr.Phone}</td>
                        <td>{usr.CompanyId}</td>
                        <td>{usr.VAT}</td>
                        <td>{usr.PayTax === "1" ? "Ano" : "Ne"}</td>
                        <td>
                          {usr.Status === "Approved" ? (
                            <Badge variant="success">Schváleno</Badge>
                          ) : (
                            <Badge variant="warning">Čekání na schválení</Badge>
                          )}
                        </td>
                        <td onClick={(e) => e.stopPropagation()}>
                          {usr.Status === "Approved" ? (
                            <>
                              <PriceListSelector
                                priceLists={priceLists}
                                loadPriceLists={loadPriceLists}
                                actList={usr.PriceList}
                                userEmail={usr.Email}
                                onPricelistChange={(newVal) => {
                                  let newUsers = [...users];
                                  newUsers[index] = { ...users[index], PriceList: newVal };
                                  console.log(newUsers);
                                  setUsers(newUsers);
                                }}
                              />
                              <UserDeletor
                                email={usr.Email}
                                onDelete={() => {
                                  let newUsers = [...users];
                                  newUsers.splice(index, 1);
                                  setUsers(newUsers);
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <OverlayTrigger placement="bottom" overlay={<Tooltip>Schválit uživatele</Tooltip>}>
                                <FontAwesomeIcon
                                  icon={faUserCheck}
                                  onClick={(e) => approveUser(index)}
                                  className="text-success pointer"
                                />
                              </OverlayTrigger>
                              <UserRejector
                                onReject={() => {
                                  let newUsers = [...users];
                                  newUsers.splice(index, 1);
                                  setUsers(newUsers);
                                }}
                                email={usr.Email}
                              />
                            </>
                          )}
                        </td>
                      </tr>
                      {showDetails.includes(usr.Email) && <ChildRow usr={usr} />}
                    </React.Fragment>
                  )
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      {users.length > rowsPerPage && (
        <Pagination className="right">
          {Array.from(Array(Math.ceil(users.length / rowsPerPage)).keys()).map((i) => (
            <Pagination.Item active={i === currentPage} onClick={(e) => setCurrentPage(i)}>
              {i + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      )}
      <br />
      <br />
      <h3>Mapa rozvozů</h3>
      <iframe
        title="DeliveryMap"
        style={{
          border: "none",
          width: "100%",
          height: "50vh",
        }}
        src={config.mapLink}
      />
    </>
  );
}
