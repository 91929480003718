import React, { useState } from "react";
import { Table, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { separateThousands } from "../../common";
import AddView from "../../Components/AddView";

function roundTo2Decimals(value) {
  return value.toFixed(2);
}


export function getProductPrice(product, ammounts, table, discountCodes) {
  const mixDiscontsFrom = [...table.format.mixDiscounts, 99999999999999999];
  let ammount = ammounts[table.id][product.id];
  let totalAmmount = Object.keys(ammounts[table.id]).reduce((sum, act) => sum + ammounts[table.id][act], 0);
  return calcCurrentPrice(product, ammount, totalAmmount, mixDiscontsFrom, discountCodes).singlePrice;
}

function getCurrentPrice(row, ammount, mixDiscountsFrom) {
  if (ammount < mixDiscountsFrom[0]) return [parseFloat(row.price) || 0.0, -1];
  else {
    let ind = 0;
    while (ammount >= mixDiscountsFrom[ind + 1]) ind++;
    return [parseFloat(row.mixDiscounts[ind]) || 0.0, ind];
  }
}

function getAmmountDiscount(row, ammount) {
  let resDisc = 0;
  let highlightedRes = [];

  row.ammountDiscountsFrom.forEach((val, ind) => {
    if (val > 0 && val <= ammount) {
      resDisc += parseInt(row.ammountDiscounts[ind]);
      highlightedRes.push(ind);
    }
  });
  return [resDisc, highlightedRes];
}

function formatExpiration(exp) {
  if (exp === undefined || exp === null || exp.length === 0) return <></>;
  const [year, month] = exp.split("-");
  let expDate = new Date(year, month, 1);
  expDate.setMonth(expDate.getMonth() - 6);
  const res = `${month}/${year.slice(-2)}`;
  if (expDate < new Date()) return <b>{res}</b>;
  return res;
}

export function calcTableStats(table, ammounts, discountCodes) {
  const totalAmmount = Object.keys(ammounts).reduce((sum, key) => sum + parseFloat(ammounts[key]), 0);
  const mixDiscontsFrom = [...table.format.mixDiscounts, 99999999999999999];
  const totalPrice = table.data
    .filter((d) => d.available)
    .map((d) => calcCurrentPrice(d, ammounts[d.id], totalAmmount, mixDiscontsFrom, discountCodes).price)
    .reduce((sum, act) => sum + act, 0);
  return {
    totalAmmount,
    totalPrice,
    mixDiscontsFrom,
    isAmmountOk: totalAmmount === 0 || totalAmmount >= table.settings.minAmmount,
  };
}

function calcCurrentPrice(row, ammount, totalAmmount, mixDiscontsFrom, discountCodes) {
  let [actPrice, mixDiscCol] = getCurrentPrice(row, totalAmmount, mixDiscontsFrom);
  const [discount, highlighedtDiscounts] = getAmmountDiscount(row, ammount);
  if (row.sukl) {
    actPrice -= discountCodes
      .filter((d) => d.Sukl === row.sukl && ammount >= d.MinAmmount)
      .map((d) => d.Discount)
      .reduce((sum, act) => sum + act, 0);
  }
  actPrice -= discount;
  return {
    price: ammount * actPrice,
    mixDiscountCol: mixDiscCol,
    highlighedtDiscounts: highlighedtDiscounts,
    singlePrice: actPrice,
    discountCodeUsed: row.sukl && discountCodes.filter((d) => d.Sukl === row.sukl && ammount >= d.MinAmmount).length > 0,
  };
}

function PriceTable({ table, ammounts, inputClickHandler, discountCodes, basedOnPriceList }) {
  const [showTable, setShowTable] = useState(!table.settings.hide);
  const { totalAmmount, totalPrice, mixDiscontsFrom, isAmmountOk } = calcTableStats(table, ammounts, discountCodes);

  const getBasedOnProduct = (product) => {
    const basedOnProduct = basedOnPriceList.tables.filter(x => x.id === table.id)[0].data.filter(x => x.id === product.id)[0];
    return basedOnProduct;
  };

  const res = (
    <>
      {table.settings.add && <AddView src={`/img/${table.settings.add}`} addLink={table.settings.addLink} />}
      <Card className="pharCard"  style={{ "borderColor": table.settings.countTowardsGifts ? "rgb(0, 175, 0)" : "#007bff" }}>
        <Card.Header style={{ "backgroundColor": table.settings.countTowardsGifts ? "rgb(0, 175, 0)" : "#007bff" }}>
          {table.name} {table.settings.minAmmount > 0 ? `( minimální odběr: ${table.settings.minAmmount} ks)` : ""}
          <FontAwesomeIcon
            className="right pointer"
            icon={showTable ? faEyeSlash : faEye}
            onClick={() => setShowTable(!showTable)}
          />
        </Card.Header>
        {showTable && (
          <Card.Body>
            <Table hover>
              <thead>
                <tr>
                  {table.format.sukl && <th>Súkl</th>}
                  {table.format.pdkCode && <th>PDK</th>}
                  <th>Název produktu</th>
                  <th>Expirace</th>
                  {table.format.percentTax && <th>% DPH</th>}
                  {table.format.basicPrice && <th>Cena</th>}
                  {table.format.mixDiscounts.map((d, i) => (
                    <th key={i}>Cena Mix&nbsp;{d}+</th>
                  ))}
                  {table.format.ammountDiscountsCount > 0 && (
                    <th colSpan={table.format.ammountDiscountsCount}>Množstevní sleva</th>
                  )}
                  {table.format.commonPrice && <th>Běžná cena</th>}
                  {table.format.suklPrice && <th>Cena dle Súkl</th>}
                  <th>Poznámka</th>
                  <th>Cena v objednávce</th>
                  <th>Množství</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {table.data.map((d) => {
                  const basedOnProduct = basedOnPriceList ? getBasedOnProduct(d) : null;
                  const { price, mixDiscountCol, highlighedtDiscounts, discountCodeUsed } = calcCurrentPrice(
                    d,
                    ammounts[d.id],
                    totalAmmount,
                    mixDiscontsFrom,
                    discountCodes
                  );
                  return (
                    <tr key={d.id} className={`${!d.available && "table-secondary"} ${d.highlighted && "table-highlighted"}`}>
                      {table.format.sukl && <td>{d.sukl}</td>}
                      {table.format.pdkCode && <td>{d.pdkCode}</td>}
                      <td>{d.name}</td>
                      <td>{formatExpiration(d.expiration)}</td>
                      {table.format.percentTax && <td>{d.percentTax}</td>}
                      {table.format.basicPrice && <td
                        dangerouslySetInnerHTML={{ __html:
                          (basedOnProduct && basedOnProduct.price > d.price ?
                            "<span style='text-decoration:line-through'>" + separateThousands(basedOnProduct.price).replace(' ', '&nbsp;') + "&nbsp;Kč</span> "
                            : "") +
                          separateThousands(d.price).replace(' ', '&nbsp;') + "&nbsp;Kč" }}
                          >
                        </td>}
                      {d.mixDiscounts.length > 0 &&
                        d.mixDiscounts.map((disc, i) => {
                          return (
                            <td key={i} className={mixDiscountCol === i ? "actPriceContainer" : ""}
                              dangerouslySetInnerHTML={{ __html:
                                (basedOnProduct && basedOnProduct.mixDiscounts[i] && basedOnProduct.mixDiscounts[i] > disc ?
                                  "<span style='text-decoration:line-through'>" + separateThousands(basedOnProduct.mixDiscounts[i]).replace(' ', '&nbsp;') + "&nbsp;Kč</span> "
                                  : "") +
                                separateThousands(disc).replace(' ', '&nbsp;') + "&nbsp;Kč" }}
                              >
                            </td>
                          );
                        })}
                      {[...Array(table.format.ammountDiscountsCount).keys()].map((ind) => (
                        <td key={ind} className={highlighedtDiscounts.includes(ind) ? "actPriceContainer" : ""}>
                          {d.ammountDiscounts.length > ind &&
                            d.ammountDiscounts[ind] !== 0 &&
                            `${d.ammountDiscounts[ind]} Kč od ${d.ammountDiscountsFrom[ind]} Ks`}
                        </td>
                      ))}
                      {table.format.commonPrice && <td>{d.commonPrice}</td>}
                      {table.format.suklPrice && <td>{d.suklPrice}</td>}
                      <td>{d.remark}</td>
                      {!d.available ? (
                        <td colSpan="2">Momentálně nedostupné</td>
                      ) : (
                        <>
                          <td style={discountCodeUsed ? { color: "green" } : {}}>{roundTo2Decimals(price)} Kč</td>
                          <td>
                            <input
                              type="number"
                              className="orderFormControll"
                              value={ammounts[d.id]}
                              min={0}
                              onChange={(e) => inputClickHandler(table.id, d.id, parseInt(e.target.value))}
                            />
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        )}
      </Card>
      <p className="right">
        <b>Celková cena za tabulku: {separateThousands(roundTo2Decimals(totalPrice))} Kč</b>
        <br />
        <span className={isAmmountOk ? "" : "text-danger"}>Celkový odběr za tabulku: {totalAmmount} ks</span>
      </p>
      <br />
      <br />
    </>
  );
  return res;
}

export default PriceTable;
