import React, { useState } from "react";
import { Button, Modal, Row, Col, FormControl } from "react-bootstrap";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import processImportData from "./importLib";
import { getEmptyDataRow } from "./TableEditor";

function getColumnsNames(format) {
  let res = [];
  if (format.sukl) res.push({ name: "SÚKL sloupec", prop: "sukl" });
  if (format.pdkCode) res.push({ name: "PDK kód sloupec", prop: "pdkCode" });
  if (format.percentTax) res.push({ name: "DPH sloupec", prop: "percentTax" });
  res.push({ name: "Název sloupec", prop: "name" });
  res.push({ name: "Expirace sloupec", prop: "expiration" });
  if (format.basicPrice) res.push({ name: "Základní cena sloupec", prop: "price" });
  format.mixDiscounts.map((d, i) => res.push({ name: `Cena MIX ${d}+ sloupec`, prop: "mixDiscounts" + i }));
  if (format.ammountDiscounts) res.push({ name: "Množstevní sleva od sloupec", prop: "ammountDiscountFrom" });
  if (format.ammountDiscounts) res.push({ name: "Množstevní sleva sloupec", prop: "ammountDiscount" });
  if (format.commonPrice) res.push({ name: "Běžná cena sloupec", prop: "commonPrice" });
  if (format.suklPrice) res.push({ name: "Cena dle SÚKL sloupec", prop: "suklPrice" });
  res.push({ name: "Poznámka sloupec", prop: "remark" });
  return res;
}

function getColumnsDefaultState(format) {
  let res = {};
  let i = 1;
  if (format.sukl) res["sukl"] = i++;
  if (format.pdkCode) res["pdkCode"] = i++;
  if (format.percentTax) res["percentTax"] = i++;
  res["name"] = i++;
  res["expiration"] = i++;
  if (format.basicPrice) res["price"] = i++;
  format.mixDiscounts.map((d, ind) => (res["mixDiscounts" + ind] = i++));
  if (format.ammountDiscounts) res["ammountDiscountFrom"] = i++;
  if (format.ammountDiscounts) res["ammountDiscount"] = i++;
  if (format.commonPrice) res["commonPrice"] = i++;
  if (format.suklPrice) res["suklPrice"] = i++;
  res["remark"] = i++;
  return res;
}

export default function ({ onImport, tableFormat }) {
  const [show, setShow] = useState(false);
  const [columns, setColumns] = useState(getColumnsDefaultState(tableFormat));
  const [file, setFile] = useState(undefined);
  const columnsDef = getColumnsNames(tableFormat);

  const resetState = () => {
    setShow(false);
    setColumns(getColumnsDefaultState(tableFormat));
    setFile(undefined);
  };

  const importData = () => {
    if (file === undefined) {
      alert("Vyberte soubor");
      return;
    }
    if (
      Object.values(columns).length !==
      [
        ...new Set(
          Object.values(columns)
            .map((v, i) => (v === 0 ? v + i * 1000 : v))
            .filter((x) => x >= 0)
        ),
      ].length
    ) {
      alert("Vyplňte všechny sloupce různými hodnotami. Pokud sloupec nechcete importovat, nechte jej prázdný");
    }
    let fileReader = new FileReader();
    const emptyRow = getEmptyDataRow(tableFormat);
    fileReader.onloadend = (e) => {
      let data = processImportData(e.target.result, columns);
      data = data.map((d) => {
        return { ...emptyRow, ...d };
      });
      onImport(data);
      resetState();
    };
    fileReader.readAsText(file);
  };

  return (
    <>
      <FontAwesomeIcon icon={faUpload} className="text-primary pointer" onClick={() => setShow(true)} />

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header className="bg-primary text-white" closeButton>
          <Modal.Title>Importovat data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} md={3}>
              Soubor
            </Col>
            <Col xs={12} md={9}>
              <FormControl type="file" accept=".csv" onChange={(e) => setFile(e.target.files[0])} />
            </Col>
          </Row>
          {columnsDef.map((col) => (
            <Row key={col.prop}>
              <Col xs={12} md={6}>
                {col.name}
              </Col>
              <Col xs={12} md={6}>
                <FormControl
                  type="number"
                  value={columns[col.prop]}
                  onChange={(e) => {
                    let newCols = { ...columns };
                    newCols[col.prop] = parseInt(e.target.value);
                    setColumns(newCols);
                  }}
                />
              </Col>
            </Row>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={resetState}>
            Zrušit
          </Button>
          <Button variant="primary" onClick={importData}>
            Importovat
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
