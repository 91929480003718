import React, { useState } from "react";
import { OverlayTrigger, Tooltip, Modal, Row, Col, Button, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";

function notifyPerEmail(email) {
  let formData = new FormData();
  formData.append("email", email);
  formData.append("subject", "Dr.Candy Pharma | V objednávkovém portálu lékárny Vám byl přidělen nový ceník");
  formData.append(
    "body",
    `
    <html>
      <body>
        V objednávkovém portálu lékárny Vám byl přidělen nový ceník.
        <br/>
        <a href="https://${window.location.hostname.replace("http://", "").replace("https://", "")}">${window.location.hostname}</a>
      </body>
    </html>`
  );
  fetch("/api/mails/sendEmail.php", {
    method: "POST",
    body: formData,
  });
}

export default function ({ priceLists, actList, loadPriceLists, onPricelistChange, userEmail }) {
  const [show, setShow] = useState(false);
  const [selectedList, setSelectedList] = useState(actList);
  const selectPriceList = () => {
    if (selectPriceList === actList) {
      resetState();
      return;
    } else {
      let formData = new FormData();
      formData.append("userEmail", userEmail);
      formData.append("listId", selectedList);
      fetch(`/api/users/changePriceList.php`, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            onPricelistChange(selectedList.toString());
            notifyPerEmail(userEmail);
            setShow(false);
          } else {
            alert("Při ukládání došlo k chybě");
            resetState();
          }
        })
        .catch(console.log);
    }
  };
  const resetState = () => {
    setShow(false);
    setSelectedList(actList);
  };
  return (
    <>
      <OverlayTrigger placement="bottom" overlay={<Tooltip>Spravovat ceníky</Tooltip>}>
        <FontAwesomeIcon
          icon={faTag}
          onClick={(e) => {
            if (priceLists === undefined) loadPriceLists();
            setShow(true);
          }}
          className={`${actList === "" || actList === null ? "text-warning" : "text-primary"} pointer`}
        />
      </OverlayTrigger>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header className="bg-primary text-white" closeButton>
          <Modal.Title>Vybrat ceník</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} md={2}>
              Ceník
            </Col>
            <Col xs={12} md={10}>
              <FormControl
                as="select"
                value={selectedList}
                onChange={(e) => {
                  setSelectedList(e.target.value);
                }}
              >
                <option value={""}></option>
                {priceLists &&
                  priceLists.map((p) => (
                    <option key={p.id} value={p.id}>
                      {p.name}
                    </option>
                  ))}
              </FormControl>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={resetState}>
            Zrušit
          </Button>
          <Button variant="primary" onClick={selectPriceList}>
            Vybrat
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
