import React, { useState } from "react";
import { Button, Modal, Row, Col, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const defaultFormat = {
  sukl: true,
  basicPrice: true,
  commonPrice: true,
  suklPrice: true,
  ammountDiscountsCount: 0,
  mixDiscounts: [],
};

export default function ({ onCreate }) {
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [format, setFormat] = useState(defaultFormat);
  const resetState = () => {
    setShow(false);
    setName("");
    setFormat(defaultFormat);
  };
  const createPriceList = () => {
    if (name === "") {
      alert("Zadejte název ceníku");
      return;
    }
    onCreate(name, format);
    resetState();
  };

  return (
    <>
      <Button variant="success" className="right" onClick={() => setShow(true)}>
        Vytvořit novou tabulku
      </Button>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header className="bg-success text-white" closeButton>
          <Modal.Title>Přidat tabulku</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} md={4}>
              Název
            </Col>
            <Col xs={12} md={8}>
              <FormControl
                type="text"
                placeholder="Název ceníku"
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></FormControl>
            </Col>
          </Row>
          <Row>
            <Col xs={11}>Súkl</Col>
            <Col xs={1}>
              <input type="checkbox" checked={format.sukl} onChange={(e) => setFormat({ ...format, sukl: !format.sukl })} />
            </Col>
          </Row>
          <Row>
            <Col xs={11}>PDK</Col>
            <Col xs={1}>
              <input type="checkbox" checked={format.pdkCode} onChange={(e) => setFormat({ ...format, pdkCode: !format.pdkCode })} />
            </Col>
          </Row>
          <Row>
            <Col xs={11}>DPH</Col>
            <Col xs={1}>
              <input type="checkbox" checked={format.percentTax} onChange={(e) => setFormat({ ...format, percentTax: !format.percentTax })} />
            </Col>
          </Row>
          <Row>
            <Col xs={11}>Základní cena</Col>
            <Col xs={1}>
              <input
                type="checkbox"
                checked={format.basicPrice}
                onChange={(e) => setFormat({ ...format, basicPrice: !format.basicPrice })}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={11}>Běžná cena</Col>
            <Col xs={1}>
              <input
                type="checkbox"
                checked={format.commonPrice}
                onChange={(e) => setFormat({ ...format, commonPrice: !format.commonPrice })}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={11}>Cena dle Súkl</Col>
            <Col xs={1}>
              <input
                type="checkbox"
                checked={format.suklPrice}
                onChange={(e) => setFormat({ ...format, suklPrice: !format.suklPrice })}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={8}>Počet množstevních slev pro 1 produkt</Col>
            <Col xs={4}>
              <FormControl
                type="number"
                onChange={(e) => setFormat({ ...format, ammountDiscountsCount: parseInt(e.target.value) })}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={11}>Slevové kategorie MIX:</Col>
            <Col xs={1}>
              <FontAwesomeIcon
                className="pointer text-success"
                icon={faPlus}
                onClick={() => setFormat({ ...format, mixDiscounts: [...format.mixDiscounts, 0] })}
              />
            </Col>
          </Row>
          {format.mixDiscounts.map((d, i) => (
            <Row key={i}>
              <Col xs={12} md={4}>
                Min. množství
              </Col>
              <Col xs={12} md={8}>
                <FormControl
                  type="number"
                  value={d}
                  onChange={(e) => {
                    let newArr = [...format.mixDiscounts];
                    newArr[i] = parseInt(e.target.value);
                    setFormat({ ...format, mixDiscounts: newArr });
                  }}
                />
              </Col>
            </Row>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={resetState}>
            Zrušit
          </Button>
          <Button variant="success" onClick={createPriceList}>
            Vytvořit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
