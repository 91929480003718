export default process.env.REACT_APP_STAGE === "jihlava"
  ? {
      enviroment: "jihlava",
      phone: "567 213 435",
      email: "lekarnaunemocnice@centrum.cz",
      oppening: "Jsme tu pro Vás pondělí až pátek 7:30-11:30, 12:00-16:00",
      header: "Lékárna U Nemocnice - Objednávkový portál lékaře",
      moto: "Nejsme distribuce, jsme rodinná Lékárna U Nemocnice s individuálním přístupem",
      noPriceListText:
        "Děkujeme za registraci. Váš účet momentálně čeká na schválení. Váš účet bude schválen, jakmile ověříme Vámi zadané údaje. Pracujeme na tom. Tým rodinné Lékárny U Nemocnice.",
      mapLink: "https://www.google.com/maps/d/embed?mid=1VS-X2d3K4Y2Dtr1kk7PN0HJ8ChpwfTt7&usp=sharing",
      personalPickupTitle: "Zboží převezmu osobně v Lékárně U Nemocnice",
      personalPickupText:
        "Po vychystání Vás budeme informovat emailem. Možnost vyzvednout si v Lékárně U Nemocnice pondělí až čtvrtek v čase 7:30-11:30, 12:00-16:30 nebo v pátek v čase 7:30-11:30, 12:00-16:00 (pokud není uvedeno jinak).",
    }
  : {
      enviroment: "selska",
      phone: "<span style='padding-right:3px'>702</span><span style='padding-right:3px'>143</span><span>944</span>",
      email: "lekarna@drcandypharma.cz",
      oppening: "Jsme tu pro Vás pondělí až pátek 7:30-17:00, mimo svátky.",
      header: "Dr.Candy Pharma – Objednávkový portál lékárny",
      moto: "<b>Babička lékárnice říkávala</b>, že si musíme pečlivě hlídat kvalitu našich produktů a z té nesmíme nikdy slevit.<br>Též si však musíme uvědomit, že nízkou cenu pacienti ocení.",
      noPriceListText:
        "Děkujeme za registraci. Váš účet momentálně čeká na schválení. Váš účet bude schválen, jakmile ověříme Vámi zadané údaje. Pracujeme na tom. Tým rodinné Lékárny Dr.Candy Pharma.",
      mapLink: "https://www.google.com/maps/d/embed?mid=1oqwmyNfVW44-_VcBLcmCSK1o_thlnzJb",
      personalPickupTitle: "Zboží převezmu osobně v Lékárně Dr.Candy Pharma",
      personalPickupText:
        "Po vychystání Vás budeme informovat emailem. Možnost vyzvednout si v Lékárně Dr.Candy Pharma 7:30 - 17:00 pondělí až pátek (pokud není uvedeno jinak).",
    };
