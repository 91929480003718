import React from "react";
import { Table, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faMinus, faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import ImportModal from "./ImportModal";

export function getEmptyDataRow(format) {
  return {
    sukl: "",
    name: "",
    expiration: `${new Date().getFullYear()}-${("0" + new Date().getMonth()).slice(-2)}`,
    percentTax: "",
    price: 0,
    mixDiscounts: format.mixDiscounts.map((x) => 0),
    ammountDiscountsFrom: new Array(format.ammountDiscountsCount).fill(0),
    ammountDiscounts: new Array(format.ammountDiscountsCount).fill(0),
    commonPrice: "",
    suklPrice: "",
    remark: "",
    available: true,
    highlighted: false,
  };
}

function EditorHeader({ isBasedOn, onDelete, onChange, table, images, numberOfTables, onSwapTables }) {
  return (
    <>
      <h3 className="left">
        {!isBasedOn && (<FontAwesomeIcon
          style={{ width: "32px", height: "32px", marginLeft: "5px"}}
          className="pointer text-danger left"
          icon={faTrash}
          onClick={onDelete}
        />)}
        &nbsp;&nbsp;{" "}
        <input
          className="editorFormControll"
          type="text"
          value={table.name}
          style={{ width: "60%" }}
          onChange={(e) => onChange({ ...table, name: e.target.value })}
        />
      </h3>
      <span className="right">
        <label>Schovat tabulku: </label>
        <input
          type="checkbox"
          checked={table.settings.hide}
          onChange={(e) => onChange({ ...table, settings: { ...table.settings, hide: !table.settings.hide } })}
          style={{ margin: "0 16px" }}
        />
        <label>Započítat do dárků: </label>
        <input
          type="checkbox"
          checked={table.settings.countTowardsGifts}
          onChange={(e) => onChange({ ...table, settings: { ...table.settings, countTowardsGifts: !table.settings.countTowardsGifts } })}
          style={{ margin: "0 16px" }}
        />
        <label>Reklama </label>
        <Form.Control
          value={table.settings.add}
          onChange={(e) => onChange({ ...table, settings: { ...table.settings, add: e.target.value } })}
          style={{ display: "inline-block", width: "150px", margin: "0 16px" }}
          as="select"
        >
          <option value={null}></option>
          {images.map((img) => (
            <option key={img} value={img}>
              {img}
            </option>
          ))}
        </Form.Control>
        {table.settings.add && (
          <>
            <label>Odkaz rek.</label>
            <Form.Control
              style={{ display: "inline-block", width: "150px", margin: "0 16px" }}
              type="text"
              value={table.settings.addLink || ""}
              onChange={(e) => onChange({ ...table, settings: { ...table.settings, addLink: e.target.value } })}
            />
          </>
        )}
        <label>Minimální množství: </label>
        <Form.Control
          style={{ display: "inline-block", width: "64px", margin: "0 16px" }}
          type="number"
          value={table.settings.minAmmount}
          onChange={(e) => onChange({ ...table, settings: { ...table.settings, minAmmount: parseInt(e.target.value) } })}
        />
        {table.order > 0 && !isBasedOn && (
        <FontAwesomeIcon
          style={{ width: "16px", height: "16px", marginLeft: "2px"}}
          icon={faArrowUp}
          className="text-primary pointer"
          onClick={() => {
            onSwapTables(table.order, table.order - 1);
          }}
        />)}

        {table.order < numberOfTables - 1 && !isBasedOn && (
        <FontAwesomeIcon
          style={{ width: "16px", height: "16px", marginLeft: "2px"}}
          icon={faArrowDown}
          className="text-primary pointer"
          onClick={() => {
            onSwapTables(table.order, table.order + 1);
          }}
        />)}
      </span>
    </>
  );
}

function EditorTableHeader({ table }) {
  return (
    <thead>
      <tr>
        <th></th>
        {table.format.sukl && <th>SÚKL</th>}
        {table.format.pdkCode && <th>PDK</th>}
        <th>Název</th>
        <th>Expirace</th>
        {table.format.percentTax && <th>% DPH</th>}
        {table.format.basicPrice && <th>Cena</th>}
        {table.format.mixDiscounts.map((d, i) => (
          <th key={i}>{`Cena MIX ${d}+`}</th>
        ))}
        {new Array(table.format.ammountDiscountsCount).fill(0).map((x) => (
          <>
            <th>Množ. sleva od</th>
            <th>Sleva</th>
          </>
        ))}
        {table.format.commonPrice && <th>Běžná cena</th>}
        {table.format.suklPrice && <th>Cena SÚKL</th>}
        <th>Poznámka</th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
    </thead>
  );
}

function swapInArray(arr, i, j) {
  let help = arr[i];
  arr[i] = arr[j];
  arr[j] = help;
}

function EditorTableBody({ onChange, table, isBasedOn }) {
  const changeProp = (i, prop, newVal) => {
    if (typeof newVal !== "string" && typeof newVal !== "object" && isNaN(newVal)) return;
    let newData = [...table.data];
    newData[i] = { ...table.data[i] };
    newData[i][prop] = newVal;
    onChange({ ...table, data: newData });
  };

  return (
    <tbody>
      {table.data.map((d, i) => (
        <tr key={i}>
          <td>
            {i > 0 && !isBasedOn && (
              <FontAwesomeIcon
                icon={faArrowUp}
                className="text-primary pointer"
                onClick={() => {
                  let newData = [...table.data];
                  swapInArray(newData, i, i - 1);
                  onChange({ ...table, data: newData });
                }}
              />
            )}
            {i < table.data.length - 1 && !isBasedOn && (
              <FontAwesomeIcon
                icon={faArrowDown}
                className="text-primary pointer"
                onClick={() => {
                  let newData = [...table.data];
                  swapInArray(newData, i, i + 1);
                  onChange({ ...table, data: newData });
                }}
              />
            )}
          </td>
          {table.format.sukl && (
            <td>
              {
                <input
                  className="editorFormControll"
                  type="text"
                  value={d.sukl}
                  disabled={isBasedOn}
                  onChange={(e) => changeProp(i, "sukl", e.target.value)}
                />
              }
            </td>
          )}
            {table.format.pdkCode && (
            <td>
              {
                <input
                  className="editorFormControll"
                  type="text"
                  value={d.pdkCode}
                  disabled={isBasedOn}
                  onChange={(e) => changeProp(i, "pdkCode", e.target.value)}
                />
              }
            </td>
          )}
          <td>
            {
              <input
                className="editorFormControll"
                type="text"
                value={d.name}
                disabled={isBasedOn}
                onChange={(e) => changeProp(i, "name", e.target.value)}
              />
            }
          </td>
          <td>
            {
              <input
                className="editorFormControll"
                type="month"
                value={d.expiration}
                disabled={isBasedOn}
                onChange={(e) => changeProp(i, "expiration", e.target.value)}
              />
            }
          </td>
          {table.format.percentTax && (
          <td>
            {
              <input
                className="editorFormControll"
                type="text"
                value={d.percentTax}
                onChange={(e) => changeProp(i, "percentTax", e.target.value)}
              />
            }
          </td>
          )}
          {table.format.basicPrice && (
            <td>
              {
                <input
                  className="editorFormControll"
                  type="number"
                  step=".01"
                  value={d.price}
                  onChange={(e) => changeProp(i, "price", parseFloat(e.target.value))}
                />
              }
            </td>
          )}
          {table.format.mixDiscounts.map((dis, ind) => (
            <td key={ind}>
              {
                <input
                  className="editorFormControll"
                  type="number"
                  step=".01"
                  value={d.mixDiscounts[ind]}
                  onChange={(e) => {
                    let newMD = [...d.mixDiscounts];
                    newMD[ind] = parseFloat(e.target.value);
                    changeProp(i, "mixDiscounts", newMD);
                  }}
                />
              }
            </td>
          ))}
          {[...Array(table.format.ammountDiscountsCount).keys()].map((ind) => (
            <>
              <td>
                {
                  <input
                    className="editorFormControll"
                    type="number"
                    step=".01"
                    value={d.ammountDiscountsFrom[ind]}
                    onChange={(e) => {
                      let newArr = [...d.ammountDiscountsFrom];
                      newArr[ind] = parseFloat(e.target.value);
                      changeProp(i, "ammountDiscountsFrom", newArr);
                    }}
                  />
                }
              </td>
              <td>
                {
                  <input
                    className="editorFormControll"
                    type="number"
                    step=".01"
                    value={d.ammountDiscounts[ind]}
                    onChange={(e) => {
                      let newArr = [...d.ammountDiscounts];
                      newArr[ind] = parseInt(e.target.value);
                      changeProp(i, "ammountDiscounts", newArr);
                    }}
                  />
                }
              </td>
            </>
          ))}
          {table.format.commonPrice && (
            <td>
              {
                <input
                  className="editorFormControll"
                  type="text"
                  value={d.commonPrice}
                  onChange={(e) => changeProp(i, "commonPrice", e.target.value)}
                />
              }
            </td>
          )}
          {table.format.suklPrice && (
            <td>
              {
                <input
                  className="editorFormControll"
                  type="text"
                  value={d.suklPrice}
                  disabled={isBasedOn}
                  onChange={(e) => changeProp(i, "suklPrice", e.target.value)}
                />
              }
            </td>
          )}
          <td>
            {
              <input
                className="editorFormControll"
                type="text"
                value={d.remark}
                disabled={isBasedOn}
                onChange={(e) => changeProp(i, "remark", e.target.value)}
              />
            }
          </td>
          <td>
            <input type="checkbox" checked={d.available} onChange={(e) => changeProp(i, "available", !d.available)} disabled={isBasedOn} />
          </td>
          <td>
            <input
              type="checkbox"
              className="editorIsHighlighted"
              checked={d.highlighted}
              disabled={isBasedOn}
              onChange={(e) => changeProp(i, "highlighted", !d.highlighted)}
            />
          </td>
          <td>
            {!isBasedOn && (<FontAwesomeIcon
              icon={faMinus}
              className="text-danger pointer"
              onClick={(e) => {
                let newData = [...table.data];
                newData.splice(i, 1);
                onChange({ ...table, data: newData });
              }}
            />)}
          </td>
        </tr>
      ))}
    </tbody>
  );
}

export default function ({ onDelete, onChange, table, images, isBasedOn, numberOfTables, onSwapTables }) {
  return (
    <>
      <EditorHeader isBasedOn={isBasedOn} onDelete={onDelete} onChange={onChange} table={table} images={images} numberOfTables={numberOfTables} onSwapTables={onSwapTables}/>
      <Table>
        <EditorTableHeader table={table} />
        <EditorTableBody table={table} onChange={onChange} isBasedOn={isBasedOn} />
      </Table>
      {!isBasedOn && (<FontAwesomeIcon
        className="pointer text-success"
        icon={faPlus}
        onClick={() => {
          onChange({ ...table, data: [...table.data, getEmptyDataRow(table.format)] });
        }}
      />)}
      &nbsp;&nbsp;
      {!isBasedOn && (<ImportModal
        tableFormat={table.format}
        onImport={(newData) => {
          onChange({ ...table, data: [...table.data, ...newData] });
        }}
      />)}
      <br />
      <br />
    </>
  );
}
