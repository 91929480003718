import React, { useState } from "react";
import { Modal, Button, FormControl } from "react-bootstrap";

export default function () {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const resetState = () => {
    setShow(false);
    setEmail("");
  };

  const sendEmail = () => {
    if (email.length === 0) {
      alert("Vyplňte email");
      return;
    }
    let formData = new FormData();
    formData.append("email", email);
    fetch(`/api/mails/generatePasswordRenewEmail.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          alert("Byl Vám odeslán email s odkazem pro obnovu hesla");
          resetState();
        } else {
          alert("Při odesílání emailu pro obnovu heslo došlo k chybě. Zkuste to prosím později.");
          resetState();
        }
      })
      .catch(console.log);
  };

  return (
    <>
      <p className="right text-primary pointer" onClick={(e) => setShow(true)}>
        Zapomněli jste heslo?
      </p>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header className="bg-primary text-white" closeButton>
          <Modal.Title>Obnova hesla</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Zadejte svůj email</p>
          <FormControl type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={resetState}>
            Zrušit
          </Button>
          <Button variant="primary" onClick={sendEmail}>
            Obnovit heslo
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
