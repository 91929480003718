import React from "react";

function Footer({ loggedIn, userName }) {
  return (
    <footer>
      {loggedIn && (
        <>
          <hr />
          <div id="ServiceInfo">
            <p>
              Veškerý obsah, zejména struktura portálu včetně prvků, produkty a informace o nich včetně cen, akcí a
              individualizovaných podmínek, uvedený v Objednávkovém portálu podléhá obchodnímu tajemství. Veškeré další šíření
              obsahu v jakékoliv formě je výslovně zakázáno a v případě zjištění porušení bude toto důrazně vymáháno. Děkujeme
              za pochopení. Jste přihlášen jako: <b>{userName}</b>
            </p>
          </div>
        </>
      )}
    </footer>
  );
}

export default Footer;
