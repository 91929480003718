import React, { useState } from "react";
import { Row, Col, Card, FormControl, Button } from "react-bootstrap";

export default function ({ match, history }) {
  const [state, setState] = useState({ email: "", pwd: "", showPwd: false });
  const renewPwd = () => {
    if (state.email.length === 0 || state.pwd.length === 0) {
      alert("Vyplňte email a heslo");
      return;
    }
    let formData = new FormData();
    formData.append("token", match.params.securityToken);
    formData.append("email", state.email);
    formData.append("newPwd", state.pwd);
    fetch(`/api/users/renewPwd.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          alert("Vaše heslo bylo změněno");
          history.push("/Login");
        } else {
          alert("Při obnově heslo došlo k chybě. Pravděpodoně jste zadali špatný email nebo platnost odkazu již vypršela.");
        }
      })
      .catch(console.log);
  };
  return (
    <Row className="justify-content-md-center">
      <Col xs={12} md={8} lg={6}>
        <Card>
          <Card.Header>Obnova hesla</Card.Header>
          <Card.Body>
            <Row>
              <Col xs={12} md={3} lg={2}>
                Email
              </Col>
              <Col xs={12} md={9} lg={4}>
                <FormControl type="text" value={state.email} onChange={(e) => setState({ ...state, email: e.target.value })} />
              </Col>
              <Col xs={12} md={3} lg={2}>
                Nové heslo
              </Col>
              <Col xs={12} md={9} lg={4}>
                <FormControl
                  type={state.showPwd ? "text" : "password"}
                  value={state.pwd}
                  onChange={(e) => setState({ ...state, pwd: e.target.value })}
                />
              </Col>
            </Row>
            <p className="right pointer text-primary" onClick={(e) => setState({ ...state, showPwd: !state.showPwd })}>
              Zobrazit heslo
            </p>
            <br />
            <br />
            <Button onClick={renewPwd} className="right">
              Obnovit heslo
            </Button>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
