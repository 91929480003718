const defaults = {
  ammountDiscountFrom: "0",
  ammountDiscount: "0",
  sukl: "",
  name: "",
  expiration: `${new Date().getFullYear()}-${("0" + new Date().getMonth()).slice(-2)}`,
  price: "0",
  mixDiscounts: "",
  suklPrice: "",
  remark: "",
  commonPrice: "",
};

const formatKeys = {
  Remark: "remark",
  Expiration: "expiration",
  SingleDiscountPrice: "ammountDiscount",
  SingleDiscountAmmount: "ammountDiscountFrom",
  Price: "price",
  MixDiscounts: "mixDiscounts",
};

function isEmpty(row) {
  return row.replace(/;/g, "").trim().length === 0;
}

function isCommentPart(row, rowData) {
  return rowData[rowData.length - 1] === row.replace(/;/g, "");
}

function isDate(key) {
  return key === formatKeys.Expiration;
}

function isPrice(key) {
  return [formatKeys.SingleDiscountPrice, formatKeys.Price].includes(key) || key.includes(formatKeys.MixDiscounts);
}

function isAmmount(key) {
  return key === formatKeys.SingleDiscountAmmount;
}

function romToArab(rom) {
  let table = {
    I: "01",
    II: "02",
    III: "03",
    IV: "04",
    V: "05",
    VI: "06",
    VII: "07",
    VIII: "08",
    IX: "09",
    X: "10",
    XI: "11",
    XII: "12",
  };
  return table[rom];
}

function formatDate(date) {
  let act = date.split(".");
  if (act.length !== 2) return "";
  return `20${act[1]}-${romToArab(act[0])}`;
}

function joinMixes(act) {
  return Object.keys(act)
    .filter((k) => k.includes("mixDiscounts"))
    .sort()
    .map((k) => act[k]);
}

function processRow(row, format) {
  if (isEmpty(row)) return [false, null];
  let rowData = row.split(";");
  if (isCommentPart(row, rowData)) return [true, rowData[rowData.length - 1]];
  let res = {};
  for (let key in format) {
    let act = rowData[format[key] - 1];
    if (act === undefined) {
      act = defaults[key];
    }
    if (isDate(key)) {
      act = formatDate(act);
    } else if (isPrice(key)) {
      act = act.split("-")[0];
      act = act.toLowerCase().replace("kč", "").replace(" ", "").replace("od", "").trim();
      if (act === "") act = defaults[key];
    } else if (isAmmount(key)) {
      act = act.toLowerCase().replace("ks", "").replace(" ", "").trim();
      if (act === "") act = defaults[key];
    }
    if (act === undefined) {
      act = defaults[key];
    }
    res[key] = act;
  }
  res[formatKeys.MixDiscounts] = joinMixes(res);
  return [false, res];
}

export default function (content, format) {
  let res = [];
  content
    .split("\n")
    .slice(1)
    .forEach((row) => {
      let [appendRemark, act] = processRow(row, format);
      if (appendRemark) res[res.length - 1][formatKeys.Remark] += act;
      else if (act != null) res.push(act);
    });
  return res;
}
