import React, { useState, useEffect } from "react";
import { Button, Modal, Row, Col, FormControl } from "react-bootstrap";

export default function ({ onCreate }) {
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [basedOn, setBasedOn] = useState(-1);
  const [priceLists, setPriceLists] = useState([]);
  useEffect(() => {
    fetch("/api/priceLists/getAll.php")
      .then((res) => res.json())
      .then((data) => {
        setPriceLists(data);
      })
      .catch(console.log);
  }, []);
  //useEffect(loadPriceLists);
  const resetState = () => {
    setShow(false);
    setName("");
    setBasedOn("");
  };
  const createPriceList = () => {
    if (name === "") {
      alert("Zadejte název ceníku");
      return;
    }
    let formData = new FormData();
    formData.append("name", name);
    formData.append("basedOn", basedOn);
    fetch(`/api/priceLists/create.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        response.json().then((res) => {
          onCreate(res);
          resetState();
        });
      })
      .catch(console.log);
  };

  return (
    <>
      <Button variant="success" className="right" onClick={() => setShow(true)}>
        Vytvořit ceník
      </Button>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header className="bg-success text-white" closeButton>
          <Modal.Title>Vytvořit ceník</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} md={3}>
              Název
            </Col>
            <Col xs={12} md={9}>
              <FormControl
                type="text"
                placeholder="Název ceníku"
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></FormControl>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={3}>
              Vycházet z
            </Col>
            <Col xs={12} md={9}>
              <FormControl as="select" value={basedOn} onChange={(e) => setBasedOn(e.target.value)}>
                <option value={-1}></option>
                {priceLists.map((priceList) => (
                  <option key={priceList.id} value={priceList.id}>
                    {priceList.name}
                  </option>
                ))}
              </FormControl>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={resetState}>
            Zrušit
          </Button>
          <Button variant="success" onClick={createPriceList}>
            Vytvořit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
