import React, { useState, useEffect } from "react";
import { numToFullDay, numToDay, reformatNoDeliveryDate } from "../../common";
import { getStartOfCurrentWeek, isWeekend } from "../../common";
import Light from "./trafficLightItem";
import "./css/calendar.css";
import "./css/trafficLight.css";
import StorageKeys from "../../localStorageKeys";
import { Alert } from "react-bootstrap";

function disabledByPriceList(date, priceList) {
  if (!priceList.settings.DeliveryDays.Mo && date.getDay() === 1) return true;
  if (!priceList.settings.DeliveryDays.Tu && date.getDay() === 2) return true;
  if (!priceList.settings.DeliveryDays.We && date.getDay() === 3) return true;
  if (!priceList.settings.DeliveryDays.Th && date.getDay() === 4) return true;
  if (!priceList.settings.DeliveryDays.Fr && date.getDay() === 5) return true;
  return false;
}

function getOppeningHours(date, oppeningHours) {
  const act = oppeningHours[numToDay(date.getDay() - 1)];
  return `${act?.TimeFrom} - ${act?.TimeTo}`;
}

function disabledByCustomer(date, oppeningHours) {
  return !Object.keys(oppeningHours).includes(numToDay(date.getDay() - 1));
}

function selectSpecialDelivery(date, specialDelivery, priceList) {
  return specialDelivery
    .filter((sd) => sd.PriceList === priceList.id || sd.PriceList === null)
    .filter((sd) => sd.Date === reformatNoDeliveryDate(date))[0];
}

function showDay(date, priceList, oppeningHoursData) {
  let today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  if (date < today) return false;
  let specialDelivery = selectSpecialDelivery(date, oppeningHoursData.specialDelivery, priceList);
  if (specialDelivery) return !specialDelivery.Remove;
  if (disabledByCustomer(date, oppeningHoursData.oppeningHours)) return false;
  if (disabledByPriceList(date, priceList)) return false;
  return true;
}

function initSelectedDays(startDate, height, priceList, oppeningHoursData) {
  let actDate = startDate;
  let res = [];
  for (let week = 0; week < height; week++) {
    for (let day = 0; day < 5; day++) {
      if (showDay(actDate, priceList, oppeningHoursData)) res.push(new Date(actDate));
      actDate.setDate(actDate.getDate() + 1);
    }
    actDate.setDate(actDate.getDate() + 2);
  }
  return res;
}

function compareDates(d1, d2) {
  return d1.getYear() === d2.getYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
}

function containsDate(dates, actDate) {
  if (dates === null) return false;
  return dates.map((d) => compareDates(d, actDate)).includes(true);
}

function getNextWorkingDays(ammount) {
  let date = new Date();
  let res = [];
  while (res.length < ammount) {
    if (!isWeekend(date)) res.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return res;
}

function canDeselectDate(startDays, selectedDays, actDate) {
  if (containsDate(startDays, actDate)) return true;
  return selectedDays.filter((d) => !containsDate(startDays, d)).length > 1;
}

export default function ({ height, selectedDays, updateSelectedDays, priceList }) {
  let actDate = getStartOfCurrentWeek();
  const startDays = getNextWorkingDays(2);
  const [oppeningHoursData, setOppeningHoursData] = useState({ oppeningHours: {}, specialDelivery: [] });
  useEffect(() => {
    let res = JSON.parse(sessionStorage.getItem(StorageKeys.CalendarStateData));
    if (res === null) {
      fetch("/api/users/get.php")
        .then((res) => {
          res.json().then((userData) => {
            fetch("/api/administration/getNoDeliveryDays.php").then((res) => {
              res.json().then((noDeliveryDays) => {
                sessionStorage.setItem(
                  StorageKeys.CalendarStateData,
                  JSON.stringify({ oppeningHours: userData.OppeningHours, specialDelivery: noDeliveryDays })
                );
                setOppeningHoursData({ oppeningHours: userData.OppeningHours, specialDelivery: noDeliveryDays });
              });
            });
          });
        })
        .catch(console.log);
    } else {
      setOppeningHoursData(res);
    }
  }, []);
  useEffect(() => {
    updateSelectedDays(initSelectedDays(getStartOfCurrentWeek(), height, priceList, oppeningHoursData));
    // eslint-disable-next-line
  }, [oppeningHoursData]);

  const clickOnDay = (day) => {
    let newSelDays;
    if (containsDate(selectedDays, day)) {
      if (canDeselectDate(startDays, selectedDays, day)) newSelDays = selectedDays.filter((d) => !compareDates(d, day));
      else newSelDays = [...selectedDays];
    } else newSelDays = [...selectedDays, day];
    updateSelectedDays(newSelDays);
  };

  return (
    <>
      <h3>Kdy chcete dodat zboží?</h3>
      <Alert variant="warning">
        Zaklikněte si v kalendáři dny, kdy je možno dodat zboží dle popisků 3 barev v semaforu níže. Čím více zelené, tím nám
        umožníte ekologičtěji a úsporněji naplánovat dopravu. Děkujeme.
      </Alert>
      <table id="calendar">
        <tbody>
          {[...Array(height).keys()].map((row) => {
            let res = (
              <tr key={row}>
                {[...Array(5).keys()].map((col) => {
                  let itemDate = new Date(actDate);
                  let res = (
                    <td key={col}>
                      {showDay(actDate, priceList, oppeningHoursData) ? (
                        <div
                          onClick={() => clickOnDay(itemDate)}
                          className={`calendarItem ${containsDate(selectedDays, actDate) ? "selected" : ""} ${
                            containsDate(startDays, actDate) ? `today` : ``
                          }`}
                        >
                          <br />
                          <span>{numToFullDay(actDate.getDay())}</span>
                          <br />
                          <span>
                            {actDate.getDate()}. {actDate.getMonth() + 1}.
                          </span>
                          <br />
                          <span>{getOppeningHours(actDate, oppeningHoursData.oppeningHours)}</span>
                        </div>
                      ) : (
                        ""
                      )}
                    </td>
                  );
                  actDate.setDate(actDate.getDate() + 1);
                  return res;
                })}
              </tr>
            );
            actDate.setDate(actDate.getDate() + 2);
            return res;
          })}
        </tbody>
      </table>
      <br />
      <div id="traffic-light">
        <Light text="ZBOŽÍ NEMŮŽU PŘEVZÍT (červené pole)" type="danger" />
        <Light
          text="ZBOŽÍ MŮŽU PŘEVZÍT (oranžové pole, zelený okraj), NEMŮŽU PŘEVZÍT (stačí kliknout a zelený okraj zmizí) | V tento den není garantováno dodání zboží (oranžové pole), nicméně 95 % produktů je skladem a připraveno k závozu"
          type="warning"
        />
        <Light
          style={{ marginTop: "23px" }}
          text="ZBOŽÍ MŮŽU PŘEVZÍT (zelené pole) | V tento den garantujeme dodání zboží"
          type="success"
        />
      </div>
      <small>Garance dodání zboží platí pro zboží, které máme skladem nebo je skladem u lékárenského distributora.</small>
    </>
  );
}
