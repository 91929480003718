import React, { useState, useEffect } from "react";
import { Card, Table, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { faEdit, faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Create from "./CreatePriceListModal";
import Delete from "./DeletePriceListModal";

function PriceLists() {
  const [priceLists, setPriceLists] = useState([]);
  useEffect(() => {
    fetch("/api/priceLists/getAll.php")
      .then((res) => res.json())
      .then((data) => {
        setPriceLists(data);
      })
      .catch(console.log);
  }, []);

  const swapRows = (r1, r2) => {
    const id1 = priceLists[r1].id;
    const id2 = priceLists[r2].id;
    let formData = new FormData();
    formData.append("firstId", id1);
    formData.append("secondId", id2);
    fetch(`/api/priceLists/swapOrder.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          let newLists = [...priceLists];
          const tmp = newLists[r1];
          newLists[r1] = newLists[r2];
          newLists[r2] = tmp;
          setPriceLists(newLists);
        } else {
          alert("Při ukládání došlo k chybě");
        }
      })
      .catch(console.log);
  };

  return (
    <>
      <Row className="justify-content-md-center">
        <Col xs={12} md={8} lg={6}>
          <Card>
            <Card.Header>Aktuální ceníky</Card.Header>
            <Card.Body>
              <Table>
                <tbody>
                  {priceLists.map((list, id) => (
                    <tr key={list.id}>
                      <td style={{ width: "64px" }}>
                        {id > 0 && (
                          <FontAwesomeIcon
                            icon={faArrowUp}
                            className="text-primary pointer"
                            onClick={() => {
                              swapRows(id, id - 1);
                            }}
                          />
                        )}
                        &nbsp;
                        {id < priceLists.length - 1 && (
                          <FontAwesomeIcon
                            icon={faArrowDown}
                            className="text-primary pointer"
                            onClick={() => {
                              swapRows(id, id + 1);
                            }}
                          />
                        )}
                      </td>
                      <td>{list.name}</td>
                      <td className="text-right">
                        <Link to={`/EditPriceList/${list.id}`}>
                          <FontAwesomeIcon icon={faEdit} />
                        </Link>
                        &nbsp;
                        <Delete
                          id={list.id}
                          onDelete={() => {
                            let newPriceLists = [...priceLists];
                            newPriceLists.splice(id, 1);
                            setPriceLists(newPriceLists);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <Create
            onCreate={(res) => {
              setPriceLists([...priceLists, res]);
            }}
          />
        </Col>
      </Row>
    </>
  );
}

export default PriceLists;
