import React from "react";

function trafficLightItem({ text, type, style }) {
  return (
    <div className="circle-container" style={style}>
      <div className={`circle bg-${type}`}></div>
      <p className="traffic-light-text">{text}</p>
    </div>
  );
}

export default trafficLightItem;
