import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, FormControl, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faMinus } from "@fortawesome/free-solid-svg-icons";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { reformatNoDeliveryDate } from "../../common";
import AddDiscountCode from "./AddDiscountCode";

export default function () {
  const [mails, setMails] = useState([]);
  const [images, setImages] = useState([]);
  const [noDeliveryDays, setNoDeliveryDays] = useState([]);
  const [discountCodes, setDiscountCodes] = useState([]);
  const [priceLists, setPriceLists] = useState([]);
  const [newMail, setNewMail] = useState("");
  const [imgUpload, setImgUpload] = useState(undefined);
  const [newNoDeliveryDay, setNewNoDeliveryDay] = useState({ day: "", pricelist: "", remove: true });
  const [defaultPriceList, setDefaultPriceList] = useState("-1");
  const [loginPageText, setLoginPageText] = useState("");

  const loadPriceLists = () => {
    fetch("/api/priceLists/getAll.php")
      .then((res) => res.json())
      .then((data) => setPriceLists(data))
      .catch(console.log);
  };

  const getPriceListById = (id) => {
    if (id === undefined || id === null) return null;
    return priceLists.filter((pl) => pl.id === id)[0]?.name;
  };

  const loadMails = () => {
    fetch("/api/administration/getAdminEmails.php")
      .then((res) => res.json())
      .then((data) => {
        setMails(data);
      })
      .catch(console.log);
  };

  const addMail = () => {
    if (newMail.length === 0) {
      alert("Zadejte email");
      return;
    }
    let formData = new FormData();
    formData.append("email", newMail);
    fetch(`/api/administration/addAdminMail.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          setMails([...mails, newMail]);
          setNewMail("");
        } else {
          alert("Při ukládání došlo k chybě");
        }
      })
      .catch(console.log);
  };

  const deleteMail = (ind) => {
    let formData = new FormData();
    formData.append("email", mails[ind]);
    fetch(`/api/administration/removeAdminMail.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          let newMails = [...mails];
          newMails.splice(ind, 1);
          setMails(newMails);
        } else {
          alert("Při ukládání došlo k chybě");
        }
      })
      .catch(console.log);
  };

  const loadImages = () => {
    fetch("/api/administration/getImages.php")
      .then((res) => res.json())
      .then((data) => {
        setImages(data);
      })
      .catch(console.log);
  };

  const deleteImage = (ind) => {
    let formData = new FormData();
    formData.append("image", images[ind]);
    fetch(`/api/administration/deleteImage.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          let newImages = [...images];
          newImages.splice(ind, 1);
          setImages(newImages);
        } else {
          alert("Při ukládání došlo k chybě");
        }
      })
      .catch(console.log);
  };

  const uploadImage = () => {
    console.log(imgUpload);
    if (imgUpload === undefined) {
      alert("Vyberte obrázek");
      return;
    }
    let formData = new FormData();
    formData.append("files[]", imgUpload);
    fetch(`/api/administration/uploadImage.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          setImages([...images, imgUpload.name]);
          setImgUpload(undefined);
        } else {
          alert("Při ukládání došlo k chybě");
        }
      })
      .catch(console.log);
  };

  const loadNoDeliveryDays = () => {
    fetch("/api/administration/getNoDeliveryDays.php")
      .then((res) => res.json())
      .then((data) => {
        setNoDeliveryDays(data.sort((a, b) => (a.Date < b.Date ? -1 : 1)));
      })
      .catch(console.log);
  };

  const addNoDeliveryDay = () => {
    if (newNoDeliveryDay.day === undefined || newNoDeliveryDay.day.length === 0) {
      alert("Vyberte datum");
      return;
    }
    let formData = new FormData();
    formData.append("day", reformatNoDeliveryDate(new Date(newNoDeliveryDay.day)));
    formData.append("priceListId", newNoDeliveryDay.pricelist);
    formData.append("remove", newNoDeliveryDay.remove ? "1" : "0");
    console.log(formData);
    fetch(`/api/administration/addNoDeliveryDay.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          setNewNoDeliveryDay({ Date: "", Pricelist: "", Remove: true });
          loadNoDeliveryDays();
          setNewNoDeliveryDay({ day: "", pricelist: "", remove: true });
        } else {
          alert("Při ukládání došlo k chybě");
        }
      })
      .catch(console.log);
  };

  const removeNoDeliveryDay = (ind) => {
    let formData = new FormData();
    formData.append("id", noDeliveryDays[ind].Id);
    fetch(`/api/administration/removeNoDeliveryDay.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          let newNoDD = [...noDeliveryDays];
          newNoDD.splice(ind, 1);
          setNoDeliveryDays(newNoDD);
        } else {
          alert("Při ukládání došlo k chybě");
        }
      })
      .catch(console.log);
  };

  const loadDiscountCodes = () => {
    fetch("/api/discountCodes/get.php?all=true")
      .then((res) => res.json())
      .then((data) => {
        setDiscountCodes(data);
      })
      .catch(console.log);
  };

  const removeDiscountCode = (code) => {
    let formData = new FormData();
    formData.append("code", code);
    fetch(`/api/discountCodes/delete.php`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          setDiscountCodes(discountCodes.filter((c) => c.Code !== code));
        } else {
          alert("Při mazání došlo k chybě");
        }
      })
      .catch(console.log);
  };

  const addDiscountCode = (formState) => {
    setDiscountCodes([
      ...discountCodes,
      {
        Code: formState.code,
        Sukl: formState.sukl,
        Description: formState.desc,
        MinAmmount: formState.minAmmount,
        Discount: formState.discount,
        ValidFrom: formState.validFrom,
        ValidTo: formState.validTo,
      },
    ]);
  };

  const loadDefaultPriceList = () => {
    fetch("/api/administration/getSetting.php?key=defaultPriceList")
      .then((res) => res.text())
      .then((data) => {
        setDefaultPriceList(data ?? "-1");
      })
      .catch(console.log)
  };

  const saveDefaultPriceList = () => {
    let formData = new FormData();
    formData.append("key", "defaultPriceList");
    formData.append("value", defaultPriceList);
    fetch("/api/administration/updateSetting.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          alert("Při ukládání došlo k chybě");
        }
      })
      .catch(console.log);
  };

  const loadLoginPageText = () => {
    fetch("/api/administration/getSetting.php?key=loginPageText")
    .then((res) => res.text())
    .then((data) => {
      setLoginPageText(data);
    })
    .catch(console.log)
  };

  const saveLoginPageText = () => {
    let formData = new FormData();
    formData.append("key", "loginPageText");
    formData.append("value", loginPageText);
    fetch("/api/administration/updateSetting.php", {
      method: "POST",
      body: formData,
    })
    .then((response) => {
      if (!response.ok) {
        alert("Při ukládání došlo k chybě");
      }
    })
    .catch(console.log);
  };

  useEffect(() => {
    loadMails();
    loadImages();
    loadPriceLists();
    loadNoDeliveryDays();
    loadDiscountCodes();
    loadDefaultPriceList();
    loadLoginPageText();
  }, []);

  return (
    <Row>
      <Col xs={12} md={6}>
        <Card>
          <Card.Header>Emaily pro notifikace aplikace</Card.Header>
          <Card.Body>
            {mails.map((mail, i) => (
              <div key={mail}>
                <FontAwesomeIcon onClick={(e) => deleteMail(i)} icon={faMinus} className="text-danger pointer" />
                &nbsp;&nbsp;{mail}
              </div>
            ))}
            <br />
            <Row>
              <Col xs={12} md={8} lg={9}>
                <FormControl placeholder="Email" type="text" value={newMail} onChange={(e) => setNewMail(e.target.value)} />
              </Col>
              <Col xs={12} md={4} lg={3}>
                <Button className="right" onClick={addMail}>
                  Přidat email
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>Obrázky</Card.Header>
          <Card.Body>
            {images.map((img, i) => (
              <div key={img}>
                <FontAwesomeIcon icon={faTrash} className="text-danger pointer" onClick={(e) => deleteImage(i)} />
                &nbsp;&nbsp;{img}
              </div>
            ))}
            <br />
            <Row>
              <Col xs={12} md={8} lg={9}>
                <FormControl type="file" onChange={(e) => setImgUpload(e.target.files[0])} />
              </Col>
              <Col xs={12} md={4} lg={3}>
                <Button className="right" onClick={uploadImage}>
                  Nahrát
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>Text na úvodní stránce</Card.Header>
          <Card.Body>
            <FormControl as="textarea" value={loginPageText} onChange={(e) => setLoginPageText(e.target.value)}>
            </FormControl>
              <Button className="right" onClick={saveLoginPageText}>
                  Uložit
              </Button>
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12} md={6}>
        <Card>
          <Card.Header>Výjimky v rozvozových dnech</Card.Header>
          <Card.Body>
            {noDeliveryDays.map((nd, i) => (
              <div key={nd.Id} className={nd.Remove ? "text-danger" : "text-success"}>
                <FontAwesomeIcon onClick={(e) => removeNoDeliveryDay(i)} icon={faMinus} className="pointer text-danger" />
                &nbsp; &nbsp; {nd.Date}: {getPriceListById(nd.PriceList) || "Všechny ceníky"}
              </div>
            ))}
            <br />
            <Row>
              <Col xs={12} md={6}>
                <FormControl
                  type="date"
                  value={newNoDeliveryDay.day}
                  onChange={(e) => setNewNoDeliveryDay({ ...newNoDeliveryDay, day: e.target.value })}
                />
              </Col>
              <Col xs={12} md={6}>
                {" "}
                <FormControl
                  as="select"
                  value={newNoDeliveryDay.pricelist}
                  onChange={(e) => {
                    setNewNoDeliveryDay({ ...newNoDeliveryDay, pricelist: e.target.value });
                  }}
                >
                  <option value={""}>Všechny ceníky</option>
                  {priceLists &&
                    priceLists.map((p) => (
                      <option key={p.id} value={p.id}>
                        {p.name}
                      </option>
                    ))}
                </FormControl>
              </Col>
              <Col xs={12}>
                <Button className="right" onClick={addNoDeliveryDay}>
                  Přidat
                </Button>
                <div className="right" style={{ marginRight: "8px" }}>
                  <BootstrapSwitchButton
                    checked={newNoDeliveryDay.remove}
                    onChange={(e) => setNewNoDeliveryDay({ ...newNoDeliveryDay, remove: !newNoDeliveryDay.remove })}
                    onlabel="Nerozvážíme"
                    offlabel="Rozvážíme"
                    onstyle="danger"
                    offstyle="success"
                    width="142"
                  />
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>Slevové kódy</Card.Header>
          <Card.Body>
            {discountCodes.length > 0 && (
              <Table>
                <thead>
                  <tr>
                    <th>Kód</th>
                    <th>Popis</th>
                    <th>Sleva pro</th>
                    <th>Sleva od</th>
                    <th>Sleva</th>
                    <th>Platnost od</th>
                    <th>Platnost do</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {discountCodes.map((c) => (
                    <tr key={c.Code}>
                      <td>{c.Code}</td>
                      <td>{c.Description}</td>
                      <td>{c.Sukl}</td>
                      <td>{c.MinAmmount}</td>
                      <td>{c.Discount} Kč</td>
                      <td>{c.ValidFrom}</td>
                      <td>{c.ValidTo}</td>
                      <td>
                        <FontAwesomeIcon
                          onClick={() => removeDiscountCode(c.Code)}
                          style={{ color: "red", cursor: "pointer" }}
                          icon={faTrash}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            <AddDiscountCode onCreate={addDiscountCode}></AddDiscountCode>
          </Card.Body>
        </Card>
        <Card>
        <Card.Header>Výchozí ceník</Card.Header>
          <Card.Body>
            <FormControl as="select" value={defaultPriceList} onChange={(e) => setDefaultPriceList(e.target.value)}>
                <option value={-1}></option>
                {priceLists.map((priceList) => (
                  <option key={priceList.id} value={priceList.id}>
                    {priceList.name}
                  </option>
                ))}
              </FormControl>
              <Button className="right" onClick={saveDefaultPriceList}>
                  Nastavit
              </Button>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
