import React from "react";
import { Row, Col } from "react-bootstrap";
import { separateThousands } from "../../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle, faCircle } from "@fortawesome/free-solid-svg-icons";

function Gifts({ gifts, selectedGift, totalPrice, totalNumberOfItems, selectGift }) {
  const isGiftAvailable = (gift) => {
    return parseInt(gift.minAmmount) <= totalPrice
      && parseInt(gift.minNumberOfItems || 0) <= totalNumberOfItems;
  };
  const giftText = (gift) => {
    if (gift.minAmmount > 0 && gift.minNumberOfItems > 0) {
      return `Při nákupu od ${separateThousands(gift.minAmmount)} Kč a ${separateThousands(gift.minNumberOfItems)} ks` +
             ` za cenu ${separateThousands(gift.price)} Kč` +
             ` (zbývá ${separateThousands(Math.max(0, parseInt(gift.minAmmount) - totalPrice))} Kč a` +
             ` ${separateThousands(Math.max(0, parseInt(gift.minNumberOfItems || 0) - totalNumberOfItems))} ks)`;
    } else if (gift.minAmmount > 0) {
      return `Při nákupu od ${separateThousands(gift.minAmmount)} Kč` +
             ` za cenu ${separateThousands(gift.price)} Kč` +
             ` (zbývá ${separateThousands(Math.max(0, parseInt(gift.minAmmount) - totalPrice))} Kč)`;
    } else if (gift.minNumberOfItems > 0) {
      return `Při nákupu od ${separateThousands(gift.minNumberOfItems)} ks` +
             ` za cenu ${separateThousands(gift.price)} Kč` +
             ` (zbývá ${separateThousands(Math.max(0, parseInt(gift.minNumberOfItems || 0) - totalNumberOfItems))} ks)`;
    } else {
      return `Za cenu ${separateThousands(gift.price)} Kč`;
    }
  };
  return (
    <div id="giftsContainer">
      {gifts.length > 0 ? (
        <h3>Vyberte si dárek navíc</h3>
      ) : (
        <>
          <br />
          <br />
        </>
      )}
      {gifts.map((g, i) => (
        <Row
          key={i}
          onClick={isGiftAvailable(g) ? () => selectGift(parseInt(i)) : () => {}}
          className={isGiftAvailable(g) ? "pointer" : ""}
        >
          <Col>
            {isGiftAvailable(g) ? (
              selectedGift === parseInt(i) ? (
                <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
              ) : (
                <FontAwesomeIcon icon={faCircle} className="text-secondary" />
              )
            ) : (
              <FontAwesomeIcon className="text-danger" icon={faTimesCircle} />
            )}
            &nbsp;<b>{g.name}: </b> {giftText(g)}
          </Col>
        </Row>
      ))}
    </div>
  );
}

export default Gifts;
